import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MandatoryDirective } from './directives/mandatory.directive';
import { InternationalizationModule } from './internationalization/internationalization.module';
import {
  FileUploadDirective,
  NumberDirective,
  NumericWithDecimalDirective,
  AlphaNumericLimitDirective,
  TooltipDirective,
} from './directives';
@NgModule({
  declarations: [
    MandatoryDirective,
    FileUploadDirective,
    NumberDirective,
    NumericWithDecimalDirective,
    AlphaNumericLimitDirective,
    TooltipDirective,
  ],
  imports: [CommonModule, InternationalizationModule],
  exports: [
    InternationalizationModule,
    MandatoryDirective,
    FileUploadDirective,
    NumberDirective,
    NumericWithDecimalDirective,
    AlphaNumericLimitDirective,
    TooltipDirective,
  ],
})
export class CoreModule {}
