import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from '../environments/environment';
import * as moment from 'moment';
@Injectable({
  providedIn: 'root',
})
export class QuestionnaireService {
  constructor(private http: HttpClient) { }
  getUserTocken() {
    let userToken = sessionStorage.getItem('loggedUserToken');
    if (!userToken) userToken = localStorage.getItem('loggedUserToken');
    return userToken;
  }

  getQuestionnaire(appointmentId, accessToken): Observable<any> {
    const userToken = this.getUserTocken();
    if (!userToken) return;
    const url = `${environment.questionnaireUrl}/questionnaire-listing/${appointmentId}`;
    return this.http.get(url, {
      headers: new HttpHeaders({
        Authorization: `Bearer ${accessToken}`,
        UserAuth: userToken,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }),
    });
    // return of(this.questionnaire); for questionnaire creation
  }

  saveResponse(data, questionnaireJson, appointmentId, accessToken): Observable<any> {
    const userToken = this.getUserTocken();
    if (!userToken) return;
    const now = moment();
    const dateNow = now.format('YYYY-MM-DD');
    const url = `${environment.questionnaireUrl}/appointment-questionnaire/${appointmentId}`;
    return this.http.patch(
      url,
      {
        questionnaire: data,
        questionnaireDate: dateNow,
        answerNQuestionFromQuestionnaire: questionnaireJson
      },
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${accessToken}`,
          UserAuth: userToken,
        }),
      }
    );
  }

  SkipNewPatientQuestionnaire(
    payload,
    patientId,
    accessToken
  ): Observable<any> {
    const userToken = this.getUserTocken();
    if (!userToken) return;

    const url = `${environment.questionnaireUrl}/new-patient-questionnaire/${patientId}`;
    return this.http.patch(url, payload, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        UserAuth: userToken,
      }),
    });
  }
  savePatientResponse(data, questionnaireJson, patientId, accessToken): Observable<any> {
    const userToken = this.getUserTocken();
    if (!userToken) return;
    const now = moment();
    const dateNow = now.format('YYYY-MM-DD');
    const url = `${environment.questionnaireUrl}/new-patient-questionnaire/${patientId}`;
    return this.http.patch(
      url,
      { newPaientQuestonnaire: data, questionnaireDate: dateNow, answerNQuestionFromQuestionnaire: questionnaireJson },
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${accessToken}`,
          UserAuth: userToken,
        }),
      }
    );
  }

  enableQuestionnaire(payload, accessToken): Observable<any> {
    const userToken = this.getUserTocken();
    if (!userToken) return;
    const url = `${environment.questionnaireUrl}/appointment-reason/${payload.itemCode}`;
    const body = {
      isQuestionnaireEnabled: payload.isQuestionnaireEnabled,
    };
    return this.http.patch(url, body, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        UserAuth: userToken,
      }),
    });
  }

  addQuestionnaire(payload, accessToken): Observable<any> {
    const userToken = this.getUserTocken();
    if (!userToken) return;
    const url = `${environment.questionnaireUrl}/questionnaire`;

    return this.http.post(url, payload, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        UserAuth: userToken,
      }),
    });
  }

  updateQuestionnaire(payload, accessToken): Observable<any> {
    const userToken = this.getUserTocken();
    if (!userToken) return;
    const url = `${environment.questionnaireUrl}/questionnaire/${payload.questionnaireId}`;
    const body = {
      questionnare: payload.questionnaire,
    };
    return this.http.patch(url, payload, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        UserAuth: userToken,
      }),
    });
  }

  getReasonQuestionnaire(reasonId, accessToken): Observable<any> {
    const userToken = this.getUserTocken();
    if (!userToken) return;
    const url = `${environment.questionnaireUrl}/reason-questionnaire/${reasonId}`;
    return this.http.get(url, {
      headers: new HttpHeaders({
        Authorization: `Bearer ${accessToken}`,
        UserAuth: userToken,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }),
    });
  }
}
