<div class="form-div form-icon" data-private>
  <img quipoTooltip="{{showIconStatus ?'Show Password':'Hide Password'}}" [tooltipPosition]="'bottom'" [src]="icon" *ngIf="icon" (click)="setVisibility('password')" />
  <input
    type="password"
    class="form-input"
    id="password"
    [required]="isRequired"
    [ngModel]="value"
    #password="ngModel"
    (focusout)="validate(password)"
    (input)="validateInput(password)"
    [placeholder]="placeholder"
    (ngModelChange)="change($event)"
  />
  <label
    for="form-input"
    class="form-label"
    [ngClass]="{ 'form-label-placeholder': placeholder != ' ' }"
    >{{ label | translate }}
    <span *ngIf="isRequired" class="mandatory-field">*</span>
  </label>
</div>

<div
  *ngIf="
    password.invalid &&
      password.errors?.required &&
      (password.dirty || password.touched || submitted);
    else validationError
  "
  class="form-validation-errors"
>
  <strong>{{ 'field-required' | translate }}</strong>
</div>
<ng-template #validationError>
  <div
    *ngIf="
      (password.dirty || password.touched || submitted) &&
      errorText &&
      doConfirm &&
      !password.errors?.required
    "
    class="form-validation-errors"
  >
    <strong>{{ errorText | translate }}</strong>
  </div>
</ng-template>

<ng-container *ngIf="doConfirm">
  <div class="form-div form-icon">
    <img
      quipoTooltip="{{showConfirmIconStatus ? 'Show Password':'Hide Password'}}" [tooltipPosition]="'bottom'"
      [src]="confirmIcon"
      *ngIf="icon"
      (click)="setVisibility('confirmPassword')"
    />
    <input
      type="password"
      class="form-input"
      id="confirmPassword"
      [required]="isRequired"
      ngModel
      name="confirmPassword"
      #confirmPassword="ngModel"
      (change)="matchValue(password, confirmPassword)"
      placeholder="&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;"
    />
    <label for="form-input" class="form-label form-label-placeholder"
      >{{ 'confirm-password' | translate }}
      <span *ngIf="isRequired" class="mandatory-field">*</span>
    </label>
  </div>

  <div
    *ngIf="
      confirmPassword.invalid &&
        confirmPassword.errors?.required &&
        (confirmPassword.dirty || confirmPassword.touched || submitted);
      else validationError
    "
    class="form-validation-errors"
  >
    <strong>{{ 'field-required' | translate }}</strong>
  </div>
  <ng-template #validationError>
    <div
      *ngIf="
        mismatchText &&
        (confirmPassword.dirty || confirmPassword.touched || submitted)
      "
      class="form-validation-errors"
    >
      <strong>{{ mismatchText | translate }}</strong>
    </div>
  </ng-template>
</ng-container>
<ng-container *ngIf="showTermsCond">
  <div class="terms-conditions">
    <label class="remember-me">
      <input type="checkbox" (change)="changeConsent($event)" />
      <label for="checkbox"
        ><span
          >{{ 'i-agree' | translate }}
          <span
            ><u>
              <a
                href="https://quipotutorials.s3.ca-central-1.amazonaws.com/Quipo-Health-Terms%26Conditions.pdf"
                target="_blank"
                >{{ 'terms-n-conditions' | translate }}</a
              ></u
            ></span
          >
          {{ 'and' | translate }}
          <span
            ><u>
              <a
                href="https://quipotutorials.s3.ca-central-1.amazonaws.com/Quipo-Health-Privacy-Policy.pdf"
                target="_blank"
                >{{ 'privacy-policy' | translate }}</a
              ></u
            ></span
          ></span
        ></label
      >
    </label>
  </div>

  <div
    *ngIf="submitted && !checkedTermsConditions"
    class="form-validation-errors"
  >
    <strong>{{ 'terms-condtions-msg' | translate }}</strong>
  </div>
</ng-container>
