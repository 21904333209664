import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { FamilyMemberState } from './family-member.state';
import { tap } from 'rxjs/operators';
import {
  ClearHealthDocumentData,
  ClearHealthSummaryAllergies,
  ClearHealthSummaryConditions,
  ClearHealthSummaryForms,
  ClearHealthSummaryHealthDocuments,
  ClearHealthSummaryImmunizations,
  ClearHealthSummaryLookupData,
  ClearHealthSummaryMedications,
  ClearHealthSummaryProcedures,
  ClearHealthSummaryResponse,
  ClearShareHealthSummaryDetails,
  FetchCareTeamMembers,
  FetchHealthSummaryAllergies,
  FetchHealthSummaryAllergy,
  FetchHealthSummaryCondition,
  FetchHealthSummaryConditions,
  FetchHealthSummaryForms,
  FetchHealthSummaryHealthDocument,
  FetchHealthSummaryHealthDocuments,
  FetchHealthSummaryImmunization,
  FetchHealthSummaryImmunizations,
  FetchHealthSummaryLookupData,
  FetchHealthSummaryMedication,
  FetchHealthSummaryMedications,
  FetchHealthSummaryMedicineLookup,
  FetchHealthSummaryProcedure,
  FetchHealthSummaryProcedures,
  FetchRecentHealthSummary,
  PatchHealthSummaryAllergy,
  PatchHealthSummaryCondition,
  PatchHealthSummaryHealthDocument,
  PatchHealthSummaryImmunization,
  PatchHealthSummaryMedication,
  PatchHealthSummaryProcedure,
  SaveHealthSummaryAllergy,
  SaveHealthSummaryCondition,
  SaveHealthSummaryHealthDocument,
  SaveHealthSummaryImmunization,
  SaveHealthSummaryMedication,
  SaveHealthSummaryProcedure,
} from '../actions/health-summary.action';
import { HealthSummaryResponse } from '../interfaces/health-summary.interface';
import { HealthSummaryService } from '../services/health-summary.service';
import { UserAuthState } from './user-auth.state';
import { FetchHealthSummaryDetails } from '@quipo/quipo-lib';

export class HealthSummaryStateModel {
  healthSummaryResponse: HealthSummaryResponse;
  conditionsList: [];
  condition: any;
  proceduresList: [];
  procedure: any;
  allergiesList: [];
  allergy: any;
  medicaitonsList: [];
  medications: any;
  medicinesLookup: [];
  immunizationsList: [];
  immunizations: any;
  healthDocumentsList: [];
  healthDocuments: any;
  lookUpList: [];
  careTeamsList: [];
  recentHealthSummary: [];
  healthSummaryDetails: any;
  forms: any;
}
@State<HealthSummaryStateModel>({
  name: 'healthSummary',
  defaults: {
    healthSummaryResponse: {
      errorMessage: undefined,
      statusCode: 0,
    },
    conditionsList: [],
    condition: {},
    proceduresList: [],
    procedure: {},
    allergiesList: [],
    allergy: {},
    medicaitonsList: [],
    medications: {},
    medicinesLookup: [],
    immunizationsList: [],
    immunizations: {},
    healthDocumentsList: [],
    healthDocuments: {},
    lookUpList: [],
    careTeamsList: [],
    recentHealthSummary: [],
    healthSummaryDetails: undefined,
    forms: [],
  },
})
@Injectable()
export class HealthSummaryState {
  constructor(
    private healthSummaryService: HealthSummaryService,
    private store: Store
  ) {}

  @Selector()
  static getSummaryResponse(state: HealthSummaryStateModel) {
    return state.healthSummaryResponse;
  }

  @Selector()
  static getCareTeamMembers(state: HealthSummaryStateModel) {
    return state.careTeamsList;
  }

  @Selector()
  static getRecentHealthSummary(state: HealthSummaryStateModel) {
    return state.recentHealthSummary;
  }

  @Selector()
  static getHealthSummaryForms(state: HealthSummaryStateModel) {
    return state.forms;
  }

  // Health summary - Conidtions
  @Selector()
  static getHealthSummaryConditions(state: HealthSummaryStateModel) {
    return state.conditionsList;
  }

  @Selector()
  static getConditionById(state: HealthSummaryStateModel) {
    return state.condition;
  }

  @Action(ClearHealthSummaryResponse)
  ClearCareteamResponse({ patchState }: StateContext<HealthSummaryStateModel>) {
    patchState({
      healthSummaryResponse: {
        errorMessage: undefined,
        statusCode: 0,
      },
    });
  }

  @Action(FetchRecentHealthSummary)
  FetchRecentHealthSummary({
    patchState,
  }: StateContext<HealthSummaryStateModel>) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    const patient = this.store.selectSnapshot(
      FamilyMemberState.getSelectedPatient
    );
    if (loginInfo && loginInfo.token) {
      return this.healthSummaryService
        .geRecenttHealthSummary(loginInfo.token, patient.id)
        .pipe(
          tap((response: any) => {
            if (response && response.statusCode) {
              if (response.statusCode == 200) {
                if (response.data) {
                  patchState({
                    recentHealthSummary: response.data,
                  });
                  return;
                }
              }
              patchState({
                recentHealthSummary: [],
              });
            }
          })
        );
    }
  }

  @Action(FetchHealthSummaryConditions)
  FetchAllConditions({ patchState }: StateContext<HealthSummaryStateModel>) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    const patient = this.store.selectSnapshot(
      FamilyMemberState.getSelectedPatient
    );
    if (loginInfo && loginInfo.token) {
      return this.healthSummaryService
        .getHealthSummaryConditions(loginInfo.token, patient.id)
        .pipe(
          tap((response: any) => {
            if (response && response.statusCode) {
              if (response.statusCode == 200) {
                if (response.data) {
                  patchState({
                    conditionsList: response.data,
                  });
                  return;
                }
              }
              patchState({
                conditionsList: [],
              });
            }
          })
        );
    }
  }

  @Action(ClearHealthSummaryConditions)
  ClearConditionsList({ patchState }: StateContext<HealthSummaryStateModel>) {
    patchState({
      conditionsList: undefined,
    });
  }

  @Action(FetchHealthSummaryCondition)
  FetchCondition(
    { patchState }: StateContext<HealthSummaryStateModel>,
    { conditionId }: any
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    const patient = this.store.selectSnapshot(
      FamilyMemberState.getSelectedPatient
    );
    const payload = {
      patientId: patient.id,
    };
    if (loginInfo && loginInfo.token) {
      return this.healthSummaryService
        .getHealthSummaryCondition(loginInfo.token, payload, conditionId)
        .pipe(
          tap((response: any) => {
            if (response && response.statusCode) {
              if (response.statusCode == 200) {
                if (response.data) {
                  patchState({
                    condition: response.data,
                  });
                  return;
                }
              }
              patchState({
                condition: {},
              });
            }
          })
        );
    }
  }

  @Action(FetchCareTeamMembers)
  FetchCareTeamMembers({ patchState }: StateContext<HealthSummaryStateModel>) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    const patient = this.store.selectSnapshot(
      FamilyMemberState.getSelectedPatient
    );
    if (loginInfo && loginInfo.token) {
      return this.healthSummaryService
        .getCareTeamMembers(loginInfo.token, patient.id)
        .pipe(
          tap((response: any) => {
            if (response && response.statusCode) {
              if (response.statusCode == 200) {
                if (response.data) {
                  const items = response.data.map(function (item) {
                    return {
                      itemCode: item?.uniqueId,
                      itemName: item?.doctorName,
                    };
                  });

                  patchState({
                    careTeamsList: items,
                  });
                  return;
                }
              }
              patchState({
                careTeamsList: [],
              });
            }
          })
        );
    }
  }

  @Action(SaveHealthSummaryCondition)
  SaveConditions(
    { patchState }: StateContext<HealthSummaryStateModel>,
    { payload }: any
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    if (loginInfo && loginInfo.token) {
      return this.healthSummaryService
        .saveHealthCareCondition(loginInfo.token, payload)
        .pipe(
          tap((response: any) => {
            if (response && response.statusCode) {
              if (response.statusCode == 200) {
                if (response.data) {
                  patchState({
                    healthSummaryResponse: {
                      statusCode: 200,
                      errorMessage: undefined,
                    },
                  });
                }
              } else {
                patchState({
                  healthSummaryResponse: {
                    statusCode: response.statusCode,
                    errorMessage: response.message ? response.message : 'Error',
                  },
                });
              }
            } else {
              patchState({
                healthSummaryResponse: {
                  statusCode: 0,
                  errorMessage: undefined,
                },
              });
            }
          })
        );
    }
  }

  @Action(PatchHealthSummaryCondition)
  UpdateCondition(
    { patchState }: StateContext<HealthSummaryStateModel>,
    { payload, conditionId }: any
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    if (loginInfo && loginInfo.token) {
      return this.healthSummaryService
        .patchHealthCareCondition(loginInfo.token, payload, conditionId)
        .pipe(
          tap((response: any) => {
            if (response && response.statusCode) {
              if (response.statusCode == 200) {
                if (response.data) {
                  patchState({
                    healthSummaryResponse: {
                      statusCode: 200,
                      errorMessage: undefined,
                    },
                  });
                }
              } else {
                patchState({
                  healthSummaryResponse: {
                    statusCode: response.statusCode,
                    errorMessage: response.message ? response.message : 'Error',
                  },
                });
              }
            } else {
              patchState({
                healthSummaryResponse: {
                  statusCode: 0,
                  errorMessage: undefined,
                },
              });
            }
          })
        );
    }
  }

  // Health Summary - Procedures
  @Selector()
  static getHealthSummaryProucedures(state: HealthSummaryStateModel) {
    return state.proceduresList;
  }

  @Selector()
  static getProcudureById(state: HealthSummaryStateModel) {
    return state.procedure;
  }

  @Action(FetchHealthSummaryProcedures)
  FetchAllProcedures({ patchState }: StateContext<HealthSummaryStateModel>) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    const patient = this.store.selectSnapshot(
      FamilyMemberState.getSelectedPatient
    );
    if (loginInfo && loginInfo.token) {
      return this.healthSummaryService
        .getHealthSummaryProcedures(loginInfo.token, patient.id)
        .pipe(
          tap((response: any) => {
            if (response && response.statusCode) {
              if (response.statusCode == 200) {
                if (response.data) {
                  patchState({
                    proceduresList: response.data,
                  });
                  return;
                }
              }
              patchState({
                proceduresList: [],
              });
            }
          })
        );
    }
  }

  @Action(FetchHealthSummaryProcedure)
  FetchProcedure(
    { patchState }: StateContext<HealthSummaryStateModel>,
    { procedureId }: any
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    const patient = this.store.selectSnapshot(
      FamilyMemberState.getSelectedPatient
    );
    let payload = {
      patientId: patient.id,
    };
    if (loginInfo && loginInfo.token) {
      return this.healthSummaryService
        .getHealthSummaryProcedure(loginInfo.token, payload, procedureId)
        .pipe(
          tap((response: any) => {
            if (response && response.statusCode) {
              if (response.statusCode == 200) {
                if (response.data) {
                  patchState({
                    procedure: response.data,
                  });
                  return;
                }
              }
              patchState({
                procedure: {},
              });
            }
          })
        );
    }
  }

  @Action(SaveHealthSummaryProcedure)
  SaveProcedure(
    { patchState }: StateContext<HealthSummaryStateModel>,
    { payload }: any
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    if (loginInfo && loginInfo.token) {
      return this.healthSummaryService
        .saveHealthCareProcedure(loginInfo.token, payload)
        .pipe(
          tap((response: any) => {
            if (response && response.statusCode) {
              if (response.statusCode == 200) {
                if (response.data) {
                  patchState({
                    healthSummaryResponse: {
                      statusCode: 200,
                      errorMessage: undefined,
                    },
                  });
                }
              } else {
                patchState({
                  healthSummaryResponse: {
                    statusCode: response.statusCode,
                    errorMessage: response.message ? response.message : 'Error',
                  },
                });
              }
            } else {
              patchState({
                healthSummaryResponse: {
                  statusCode: 0,
                  errorMessage: undefined,
                },
              });
            }
          })
        );
    }
  }

  @Action(PatchHealthSummaryProcedure)
  UpdateProcedure(
    { patchState }: StateContext<HealthSummaryStateModel>,
    { payload, procedureId }: any
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    if (loginInfo && loginInfo.token) {
      return this.healthSummaryService
        .patchHealthCareProcedure(loginInfo.token, payload, procedureId)
        .pipe(
          tap((response: any) => {
            if (response && response.statusCode) {
              if (response.statusCode == 200) {
                if (response.data) {
                  patchState({
                    healthSummaryResponse: {
                      statusCode: 200,
                      errorMessage: undefined,
                    },
                  });
                }
              } else {
                patchState({
                  healthSummaryResponse: {
                    statusCode: response.statusCode,
                    errorMessage: response.message ? response.message : 'Error',
                  },
                });
              }
            } else {
              patchState({
                healthSummaryResponse: {
                  statusCode: 0,
                  errorMessage: undefined,
                },
              });
            }
          })
        );
    }
  }

  // Health Summary - ALLERGIES
  @Selector()
  static getHealthSummaryAllergies(state: HealthSummaryStateModel) {
    return state.allergiesList;
  }

  @Selector()
  static getAllergyById(state: HealthSummaryStateModel) {
    return state.allergy;
  }

  @Action(FetchHealthSummaryAllergies)
  FetchAllAllergies({ patchState }: StateContext<HealthSummaryStateModel>) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    const patient = this.store.selectSnapshot(
      FamilyMemberState.getSelectedPatient
    );
    if (loginInfo && loginInfo.token) {
      return this.healthSummaryService
        .getHealthSummaryAllergies(loginInfo.token, patient.id)
        .pipe(
          tap((response: any) => {
            if (response && response.statusCode) {
              if (response.statusCode == 200) {
                if (response.data) {
                  patchState({
                    allergiesList: response.data,
                  });
                  return;
                }
              }
              patchState({
                allergiesList: [],
              });
            }
          })
        );
    }
  }

  @Action(FetchHealthSummaryAllergy)
  FetchAllergy(
    { patchState }: StateContext<HealthSummaryStateModel>,
    { allergyId }: any
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    const patient = this.store.selectSnapshot(
      FamilyMemberState.getSelectedPatient
    );
    let payload = {
      patientId: patient.id,
    };
    if (loginInfo && loginInfo.token) {
      return this.healthSummaryService
        .getHealthSummaryAllergy(loginInfo.token, payload, allergyId)
        .pipe(
          tap((response: any) => {
            if (response && response.statusCode) {
              if (response.statusCode == 200) {
                if (response.data) {
                  patchState({
                    allergy: response.data,
                  });
                  return;
                }
              }
              patchState({
                allergy: {},
              });
            }
          })
        );
    }
  }

  @Action(SaveHealthSummaryAllergy)
  SaveAllergyData(
    { patchState }: StateContext<HealthSummaryStateModel>,
    { payload }: any
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    if (loginInfo && loginInfo.token) {
      return this.healthSummaryService
        .saveHealthCareAllergy(loginInfo.token, payload)
        .pipe(
          tap((response: any) => {
            if (response && response.statusCode) {
              if (response.statusCode == 200) {
                if (response.data) {
                  patchState({
                    healthSummaryResponse: {
                      statusCode: 200,
                      errorMessage: undefined,
                    },
                  });
                }
              } else {
                patchState({
                  healthSummaryResponse: {
                    statusCode: response.statusCode,
                    errorMessage: response.message ? response.message : 'Error',
                  },
                });
              }
            } else {
              patchState({
                healthSummaryResponse: {
                  statusCode: 0,
                  errorMessage: undefined,
                },
              });
            }
          })
        );
    }
  }

  @Action(PatchHealthSummaryAllergy)
  UpdateAllergy(
    { patchState }: StateContext<HealthSummaryStateModel>,
    { payload, allergyId }: any
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    if (loginInfo && loginInfo.token) {
      return this.healthSummaryService
        .patchHealthCareAllergy(loginInfo.token, payload, allergyId)
        .pipe(
          tap((response: any) => {
            if (response && response.statusCode) {
              if (response.statusCode == 200) {
                if (response.data) {
                  patchState({
                    healthSummaryResponse: {
                      statusCode: 200,
                      errorMessage: undefined,
                    },
                  });
                }
              } else {
                patchState({
                  healthSummaryResponse: {
                    statusCode: response.statusCode,
                    errorMessage: response.message ? response.message : 'Error',
                  },
                });
              }
            } else {
              patchState({
                healthSummaryResponse: {
                  statusCode: 0,
                  errorMessage: undefined,
                },
              });
            }
          })
        );
    }
  }

  // Health Summary - MEDICATIONS
  @Selector()
  static getHealthSummaryMedications(state: HealthSummaryStateModel) {
    return state.medicaitonsList;
  }

  @Selector()
  static getMedicationById(state: HealthSummaryStateModel) {
    return state.medications;
  }

  @Selector()
  static getMedicinesLookup(state: HealthSummaryStateModel) {
    return state.medicinesLookup;
  }

  @Action(FetchHealthSummaryMedications)
  FetchAllMedications({ patchState }: StateContext<HealthSummaryStateModel>) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    const patient = this.store.selectSnapshot(
      FamilyMemberState.getSelectedPatient
    );
    if (loginInfo && loginInfo.token) {
      return this.healthSummaryService
        .getHealthSummaryMedications(loginInfo.token, patient.id)
        .pipe(
          tap((response: any) => {
            if (response && response.statusCode) {
              if (response.statusCode == 200) {
                if (response.data) {
                  patchState({
                    medicaitonsList: response.data,
                  });
                  return;
                }
              }
              patchState({
                medicaitonsList: [],
              });
            }
          })
        );
    }
  }

  @Action(FetchHealthSummaryMedicineLookup)
  FetchAllMedcinesLookup({
    patchState,
  }: StateContext<HealthSummaryStateModel>) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    if (loginInfo && loginInfo.token) {
      return this.healthSummaryService
        .getHealthSummaryMedicinesLookup(loginInfo.token)
        .pipe(
          tap((response: any) => {
            if (response && response.statusCode) {
              if (response.statusCode == 200) {
                if (response.data) {
                  patchState({
                    medicinesLookup: response.data,
                  });
                  return;
                }
              }
              patchState({
                medicinesLookup: [],
              });
            }
          })
        );
    }
  }

  @Action(FetchHealthSummaryMedication)
  FetchMedication(
    { patchState }: StateContext<HealthSummaryStateModel>,
    { medicationId }: any
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    const patient = this.store.selectSnapshot(
      FamilyMemberState.getSelectedPatient
    );
    let payload = {
      patientId: patient.id,
    };
    if (loginInfo && loginInfo.token) {
      return this.healthSummaryService
        .getHealthSummaryMedication(loginInfo.token, payload, medicationId)
        .pipe(
          tap((response: any) => {
            if (response && response.statusCode) {
              if (response.statusCode == 200) {
                if (response.data) {
                  patchState({
                    medications: response.data,
                  });
                  return;
                }
              }
              patchState({
                medications: [],
              });
            }
          })
        );
    }
  }

  @Action(SaveHealthSummaryMedication)
  SaveMedicationData(
    { patchState }: StateContext<HealthSummaryStateModel>,
    { payload }: any
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    if (loginInfo && loginInfo.token) {
      return this.healthSummaryService
        .saveHealthCareMedication(loginInfo.token, payload)
        .pipe(
          tap((response: any) => {
            if (response && response.statusCode) {
              if (response.statusCode == 200) {
                if (response.data) {
                  patchState({
                    healthSummaryResponse: {
                      statusCode: 200,
                      errorMessage: undefined,
                    },
                  });
                }
              } else {
                patchState({
                  healthSummaryResponse: {
                    statusCode: response.statusCode,
                    errorMessage: response.message ? response.message : 'Error',
                  },
                });
              }
            } else {
              patchState({
                healthSummaryResponse: {
                  statusCode: 0,
                  errorMessage: undefined,
                },
              });
            }
          })
        );
    }
  }

  @Action(PatchHealthSummaryMedication)
  UpdateMedication(
    { patchState }: StateContext<HealthSummaryStateModel>,
    { payload, medicationId }: any
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    if (loginInfo && loginInfo.token) {
      return this.healthSummaryService
        .patchHealthCareMedication(loginInfo.token, payload, medicationId)
        .pipe(
          tap((response: any) => {
            if (response && response.statusCode) {
              if (response.statusCode == 200) {
                if (response.data) {
                  patchState({
                    healthSummaryResponse: {
                      statusCode: 200,
                      errorMessage: undefined,
                    },
                  });
                }
              } else {
                patchState({
                  healthSummaryResponse: {
                    statusCode: response.statusCode,
                    errorMessage: response.message ? response.message : 'Error',
                  },
                });
              }
            } else {
              patchState({
                healthSummaryResponse: {
                  statusCode: 0,
                  errorMessage: undefined,
                },
              });
            }
          })
        );
    }
  }

  // Health Summary - IMMUNIZATIONS
  @Selector()
  static getHealthSummaryImmunizations(state: HealthSummaryStateModel) {
    return state.immunizationsList;
  }

  @Selector()
  static getImmunizationDetailById(state: HealthSummaryStateModel) {
    return state.immunizations;
  }

  @Action(FetchHealthSummaryImmunizations)
  FetchAllImmunications({ patchState }: StateContext<HealthSummaryStateModel>) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    const patient = this.store.selectSnapshot(
      FamilyMemberState.getSelectedPatient
    );
    if (loginInfo && loginInfo.token) {
      return this.healthSummaryService
        .getHealthSummaryImmunizations(loginInfo.token, patient.id)
        .pipe(
          tap((response: any) => {
            if (response && response.statusCode) {
              if (response.statusCode == 200) {
                if (response.data) {
                  patchState({
                    immunizationsList: response.data,
                  });
                  return;
                }
              }
              patchState({
                immunizationsList: [],
              });
            }
          })
        );
    }
  }

  @Action(FetchHealthSummaryImmunization)
  FetchImmunization(
    { patchState }: StateContext<HealthSummaryStateModel>,
    { immunizationId }: any
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    const patient = this.store.selectSnapshot(
      FamilyMemberState.getSelectedPatient
    );
    let payload = {
      patientId: patient.id,
    };
    if (loginInfo && loginInfo.token) {
      return this.healthSummaryService
        .getHealthSummaryImmunization(loginInfo.token, payload, immunizationId)
        .pipe(
          tap((response: any) => {
            if (response && response.statusCode) {
              if (response.statusCode == 200) {
                if (response.data) {
                  patchState({
                    immunizations: response.data,
                  });
                  return;
                }
              }
              patchState({
                immunizations: {},
              });
            }
          })
        );
    }
  }

  @Action(SaveHealthSummaryImmunization)
  SaveImmunizationData(
    { patchState }: StateContext<HealthSummaryStateModel>,
    { payload }: any
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    if (loginInfo && loginInfo.token) {
      return this.healthSummaryService
        .saveHealthCareImmunization(loginInfo.token, payload)
        .pipe(
          tap((response: any) => {
            if (response && response.statusCode) {
              if (response.statusCode == 200) {
                if (response.data) {
                  patchState({
                    healthSummaryResponse: {
                      statusCode: 200,
                      errorMessage: undefined,
                    },
                  });
                }
              } else {
                patchState({
                  healthSummaryResponse: {
                    statusCode: response.statusCode,
                    errorMessage: response.message ? response.message : 'Error',
                  },
                });
              }
            } else {
              patchState({
                healthSummaryResponse: {
                  statusCode: 0,
                  errorMessage: undefined,
                },
              });
            }
          })
        );
    }
  }

  @Action(PatchHealthSummaryImmunization)
  UpdateImmunization(
    { patchState }: StateContext<HealthSummaryStateModel>,
    { payload, immunizationId }: any
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    if (loginInfo && loginInfo.token) {
      return this.healthSummaryService
        .patchHealthCareImmunization(loginInfo.token, payload, immunizationId)
        .pipe(
          tap((response: any) => {
            if (response && response.statusCode) {
              if (response.statusCode == 200) {
                if (response.data) {
                  patchState({
                    healthSummaryResponse: {
                      statusCode: 200,
                      errorMessage: undefined,
                    },
                  });
                }
              } else {
                patchState({
                  healthSummaryResponse: {
                    statusCode: response.statusCode,
                    errorMessage: response.message ? response.message : 'Error',
                  },
                });
              }
            } else {
              patchState({
                healthSummaryResponse: {
                  statusCode: 0,
                  errorMessage: undefined,
                },
              });
            }
          })
        );
    }
  }

  // Health Summary - HEALTH-DOCUMENTS
  @Selector()
  static getHealthDocuments(state: HealthSummaryStateModel) {
    return state.healthDocumentsList;
  }

  @Selector()
  static getHealthDocumentDetailById(state: HealthSummaryStateModel) {
    return state.healthDocuments;
  }

  @Selector()
  static getHealthDocumentLookup(state: HealthSummaryStateModel) {
    return state.lookUpList;
  }

  @Action(ClearHealthDocumentData)
  clearHealthDocument({ patchState }: StateContext<HealthSummaryStateModel>) {
    patchState({
      healthDocuments: [],
    });
  }

  @Action(FetchHealthSummaryHealthDocuments)
  FetchAllHealthDocuments({
    patchState,
  }: StateContext<HealthSummaryStateModel>) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    const patient = this.store.selectSnapshot(
      FamilyMemberState.getSelectedPatient
    );
    if (loginInfo && loginInfo.token) {
      return this.healthSummaryService
        .getHealthSummaryHealthDocuments(loginInfo.token, patient.id)
        .pipe(
          tap((response: any) => {
            if (response && response.statusCode) {
              if (response.statusCode == 200) {
                if (response.data) {
                  patchState({
                    healthDocumentsList: response.data,
                  });
                  return;
                }
              }
              patchState({
                healthDocumentsList: [],
              });
            }
          })
        );
    }
  }

  @Action(FetchHealthSummaryHealthDocument)
  FetchHealthDocument(
    { patchState }: StateContext<HealthSummaryStateModel>,
    { documentId }: any
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    const patient = this.store.selectSnapshot(
      FamilyMemberState.getSelectedPatient
    );
    let payload = {
      patientId: patient.id,
    };
    if (loginInfo && loginInfo.token) {
      return this.healthSummaryService
        .getHealthSummaryHealthDocumentById(
          loginInfo.token,
          payload,
          documentId
        )
        .pipe(
          tap((response: any) => {
            if (response && response.statusCode) {
              if (response.statusCode == 200) {
                if (response.data) {
                  patchState({
                    healthDocuments: response.data,
                  });
                  return;
                }
              }
              patchState({
                healthDocuments: {},
              });
            }
          })
        );
    }
  }

  @Action(SaveHealthSummaryHealthDocument)
  SaveHealthDocumentData(
    { patchState }: StateContext<HealthSummaryStateModel>,
    { payload }: any
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    if (loginInfo && loginInfo.token) {
      return this.healthSummaryService
        .saveHealthCareHealthDocument(loginInfo.token, payload)
        .pipe(
          tap((response: any) => {
            if (response && response.statusCode) {
              if (response.statusCode == 200) {
                if (response.data) {
                  patchState({
                    healthSummaryResponse: {
                      statusCode: 200,
                      errorMessage: undefined,
                    },
                  });
                }
              } else {
                patchState({
                  healthSummaryResponse: {
                    statusCode: response.statusCode,
                    errorMessage: response.message ? response.message : 'Error',
                  },
                });
              }
            } else {
              patchState({
                healthSummaryResponse: {
                  statusCode: 0,
                  errorMessage: undefined,
                },
              });
            }
          })
        );
    }
  }

  @Action(PatchHealthSummaryHealthDocument)
  UpdateHealthDocument(
    { patchState }: StateContext<HealthSummaryStateModel>,
    { payload, documentId }: any
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    if (loginInfo && loginInfo.token) {
      return this.healthSummaryService
        .patchHealthCareHealthDocument(loginInfo.token, payload, documentId)
        .pipe(
          tap((response: any) => {
            if (response && response.statusCode) {
              if (response.statusCode == 200) {
                if (response.data) {
                  patchState({
                    healthSummaryResponse: {
                      statusCode: 200,
                      errorMessage: undefined,
                    },
                  });
                }
              } else {
                patchState({
                  healthSummaryResponse: {
                    statusCode: response.statusCode,
                    errorMessage: response.message ? response.message : 'Error',
                  },
                });
              }
            } else {
              patchState({
                healthSummaryResponse: {
                  statusCode: 0,
                  errorMessage: undefined,
                },
              });
            }
          })
        );
    }
  }

  @Action(FetchHealthSummaryLookupData)
  FetchHealthDocumentLookup(
    { patchState }: StateContext<HealthSummaryStateModel>,
    { lookupItem }: any
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    const patient = this.store.selectSnapshot(
      FamilyMemberState.getSelectedPatient
    );
    if (loginInfo && loginInfo.token) {
      return this.healthSummaryService
        .fetchHealthDocumentLookup(loginInfo.token, lookupItem, patient.id)
        .pipe(
          tap((response: any) => {
            if (response && response.statusCode) {
              if (response.statusCode == 200) {
                if (response.data) {
                  patchState({
                    lookUpList: response.data,
                  });
                  return;
                }
              }
              patchState({
                lookUpList: [],
              });
            }
          })
        );
    }
  }

  @Selector()
  static getHealthSummaryDetails(state: HealthSummaryStateModel) {
    return state.healthSummaryDetails;
  }

  @Action(FetchHealthSummaryDetails)
  fetchHealthSummaryDetails(
    { patchState }: StateContext<HealthSummaryStateModel>,
    { payload }: any
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    return this.healthSummaryService
      .fetchHealthSumaryDetails(payload, loginInfo.token)
      .pipe(
        tap((response) => {
          if (response && response.statusCode) {
            if (response.statusCode == 200) {
              patchState({
                healthSummaryDetails: response.data,
              });
            }
          }
        })
      );
  }

  @Action(ClearHealthSummaryLookupData)
  clearLookUp({ patchState }: StateContext<HealthSummaryStateModel>) {
    patchState({
      lookUpList: [],
    });
  }

  @Action(ClearHealthSummaryLookupData)
  clearHealthSummaryData({
    patchState,
  }: StateContext<HealthSummaryStateModel>) {
    patchState({
      conditionsList: [],
      condition: {},
      proceduresList: [],
      procedure: {},
      allergiesList: [],
      allergy: {},
      medicaitonsList: [],
      medications: {},
      medicinesLookup: [],
      immunizationsList: [],
      immunizations: {},
      healthDocumentsList: [],
      healthDocuments: {},
      lookUpList: [],
      careTeamsList: [],
      recentHealthSummary: [],
    });
  }

  @Action(ClearShareHealthSummaryDetails)
  clearShareHealthSummary({
    patchState,
  }: StateContext<HealthSummaryStateModel>) {
    patchState({
      healthSummaryDetails: undefined,
    });
  }

  @Action(ClearHealthSummaryProcedures)
  ClearProceduresList({ patchState }: StateContext<HealthSummaryStateModel>) {
    patchState({
      proceduresList: undefined,
    });
  }
  @Action(ClearHealthSummaryAllergies)
  ClearAllergiesList({ patchState }: StateContext<HealthSummaryStateModel>) {
    patchState({
      allergiesList: undefined,
    });
  }
  @Action(ClearHealthSummaryImmunizations)
  ClearImmunizationsList({
    patchState,
  }: StateContext<HealthSummaryStateModel>) {
    patchState({
      immunizationsList: undefined,
    });
  }
  @Action(ClearHealthSummaryMedications)
  ClearMedicationsList({ patchState }: StateContext<HealthSummaryStateModel>) {
    patchState({
      medicaitonsList: undefined,
    });
  }
  @Action(ClearHealthSummaryHealthDocuments)
  ClearHealthDocumentsList({
    patchState,
  }: StateContext<HealthSummaryStateModel>) {
    patchState({
      healthDocumentsList: undefined,
    });
  }

  @Action(ClearHealthSummaryForms)
  ClearHealthSummaryForms({
    patchState,
  }: StateContext<HealthSummaryStateModel>) {
    patchState({
      forms: undefined,
    });
  }

  @Action(FetchHealthSummaryForms)
  fetchForms({ patchState }: StateContext<HealthSummaryStateModel>) {
    const loginInfo = this.store.selectSnapshot(UserAuthState.getUserAuthDetails);
    const patient = this.store.selectSnapshot(FamilyMemberState.getSelectedPatient);
    const patientId = patient.patientId ? patient.patientId : patient.id;
    return this.healthSummaryService
      .getHealthSummaryForms(loginInfo.token, patientId)
      .pipe(
        tap((response) => {
          if (response && response.statusCode) {
            if (response.statusCode == 200) {
              patchState({
                forms: response.data,
              });
            }
          }
        })
      );
  }
}
