import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'quipo-password-input',
  templateUrl: './password-input.component.html',
  styleUrls: ['./password-input.component.scss'],
})
export class PasswordInputComponent {
  @Input() doConfirm: boolean;
  @Input() showTermsCond: boolean;
  @Input() isRequired: boolean;
  @Input() icon;
  @Input() submitted: boolean;
  @Input() label: string;
  @Input() placeholder: string;
  @Input() value: string;
  @Output() valueChange = new EventEmitter();
  @Output() consentAcceptanceValue = new EventEmitter();
  inputContent: string;
  checkedTermsConditions = false;
  passwordPattern = new RegExp(
    '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*?,_/+=])(?=.{8,})'
  );
  errorText = '';
  mismatchText = '';
  confirmIcon = 'assets/images/eye-icon.svg';
  showIconStatus: boolean = true;
  showConfirmIconStatus: boolean = true

  constructor() {
    this.errorText = null;
    this.mismatchText = null;
    this.submitted = false;
    this.isRequired = false;
  }
  change(newValue: string) {
    this.valueChange.emit(newValue);
    if (!this.errorText) {
      if (this.doConfirm && this.mismatchText) return null;
    }
  }

  changeConsent(event) {
    this.checkedTermsConditions = event.target.checked;
    this.consentAcceptanceValue.emit(this.checkedTermsConditions);
  }

  setVisibility(id: string) {
    const ele = <HTMLInputElement>document.getElementById(id);
    if (ele && ele.type === 'password') {
      ele.type = 'text';
      if (id == 'password') {
        this.icon = 'assets/images/hide.png';
        this.showIconStatus = false
      }else{
        this.confirmIcon = 'assets/images/hide.png';
        this.showConfirmIconStatus = false
      }
    } else if (ele) {
      ele.type = 'password';
      if (id == 'password'){
        this.icon = 'assets/images/eye-icon.svg';
        this.showIconStatus = true
      } else{
        this.confirmIcon = 'assets/images/eye-icon.svg';
        this.showConfirmIconStatus = true
      }
    }
  }

  validate(elemId) {
    const data = elemId.control.value;
    this.passwordPattern.test(data)
      ? (this.errorText = null)
      : (this.errorText = 'password-validation');
  }

  validateInput(elemId) {
    const data = elemId.control.value;
    if (this.passwordPattern.test(data)) this.errorText = null;
  }

  matchValue(elemId, confirmElemId) {
    elemId.control.value === confirmElemId.control.value
      ? (this.mismatchText = null)
      : (this.mismatchText = 'password-mismatch');
  }
}
