<div class="auto-complete-div">
  <div class="form-div">
    <div class="autocomplete-input">
      <ng-autocomplete
        [placeholder]="itemName"
        [data]="filteredItems"
        [searchKeyword]="value"
        [(ngModel)]="value"
        (selected)="onSelect($event)"
        (inputChanged)="search($event)"
        [itemTemplate]="itemTemplate"
        #FirstAutoCompleteAddress="ngModel"
        [customFilter]="customFilter"
        [isLoading]="isLoading"
        [debounceTime]="3"
      >
      </ng-autocomplete>
      <ng-template #itemTemplate let-item>
        <a [innerHTML]="item"></a>
      </ng-template>
      <!-- <ng-template #notFoundTemplate let-notFound>
        <div [innerHTML]="notFound"></div>
      </ng-template> -->
    </div>
    <label class="form-label form-label-placeholder"
      >{{ itemName | translate }}
      <span *ngIf="isRequired" class="mandatory-field">*</span>
    </label>
  </div>

  <div
    *ngIf="
      isInvalid ||
      (isRequired &&
        !value &&
        (submitted || FirstAutoCompleteAddress.touched || FirstAutoCompleteAddress.dirty))
    "
  >
    <strong class="form-validation-errors">{{
      'field-required' | translate
    }}</strong>
  </div>
</div>

<div class="auto-complete-div">
  <div class="form-div pharmAddress" *ngIf="showPharmAddr">
    <div class="autocomplete-input">
      <ng-autocomplete
        [(ngModel)]="pharmAddress"
        placeholder="Pharmacy Address"
        [data]="filteredItems"
        [searchKeyword]="pharmAddress"
        (selected)="onPharmAddrSelect($event)"
        (inputChanged)="pharAddrSearch($event)"
        [itemTemplate]="itemTemplate"
        [isRequired]="true"
        #pharmHash="ngModel"
      >
      </ng-autocomplete>
      <ng-template #itemTemplate let-item>
        <a [innerHTML]="item"></a>
      </ng-template>
    </div>
    <label class="form-label form-label-placeholder"
      >{{ 'pharm-address' | translate }}
      <span *ngIf="isRequired" class="mandatory-field">*</span>
    </label>
  </div>
</div>

<div
  class="err-div"
  *ngIf="showPharmAddr && submitted && (value || pharmAddress)"
>
  <strong class="form-validation-errors" *ngIf="!(value && pharmAddress)">
    {{ 'pharmacy-validation' | translate }}
  </strong>
</div>
