import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import {
  AddPatientResponse,
  AddQuestionnaire,
  ClearAnswerResponse,
  ClearSkipNewPatientQuestionnaire,
  EnableQuestionnaire,
  FetchQuestionnaire,
  FetchReasonQuestionnaire,
  UpdateQuestionnaire,
  SkipNewPatientQuestionnaire,
  ClearQuestionnaire,
} from '../actions/questionnaire.action';
import { FetchAllReasons } from '../actions/appointment.action';
import { QuestionnaireService } from '../services/questionnaire.service';
import { AppointmentState } from './appointment.state';
import { UserAuthState } from './user-auth.state';
import { Router } from '@angular/router';
import { SweetAlertService } from '../../services';
import { FamilyMemberState, VirtualCareState } from '@quipo/quipo-lib';
export class QuestionnaireStateModel {
  questionnaireList: any;
  answerSaveSuccess: any;
  reasonQuestionnaire: any;
  questionnaireSkippedResponse: any;
}

@State<QuestionnaireStateModel>({
  name: 'questionnaire',
  defaults: {
    questionnaireList: [],
    answerSaveSuccess: undefined,
    reasonQuestionnaire: undefined,
    questionnaireSkippedResponse: undefined,
  },
})
@Injectable()
export class QuestionnaireState {
  constructor(
    private store: Store,
    private questionnaireService: QuestionnaireService,
    private router: Router,
    private alert: SweetAlertService
  ) { }

  @Selector()
  static getQuestionnaire(state: QuestionnaireStateModel) {
    return state.questionnaireList;
  }
  @Selector()
  static getAnswerPost(state: QuestionnaireStateModel) {
    return state.answerSaveSuccess;
  }
  @Selector()
  static getquestionnaireSkippedResponse(state: QuestionnaireStateModel) {
    return state.questionnaireSkippedResponse;
  }

  @Selector()
  static getReasonQuestionnaire(state: QuestionnaireStateModel) {
    return state.reasonQuestionnaire;
  }

  @Action(FetchQuestionnaire)
  FetchQuestionnaire(
    { patchState }: StateContext<QuestionnaireStateModel>,
    { payload }: any
  ) {
    const token = this.store.selectSnapshot(UserAuthState.getUserAuthDetails)
      .token;
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    const selected = this.store.selectSnapshot(
      FamilyMemberState.getSelectedPatient
    );
    const userDetail = selected ? selected : loginInfo;
    return this.questionnaireService.getQuestionnaire(payload, token).pipe(
      tap((response) => {
        if (response && response.statusCode) {
          if (response.statusCode == 200 && response.data) {
            const data = response.data;
            if (data &&
              data.reasonId == 104 &&
              userDetail.gender?.itemName.toLowerCase() == 'male' &&
              data.questionnare.components &&
              data.questionnare.components[0]
            ) {
              const compo = data.questionnare.components[0]?.components;
              if (compo) {
                compo.forEach((element, index) => {
                  if (element.key == 'femaleSymptoms') {
                    compo.splice(index, 1);
                  }
                });
              }
              patchState({
                questionnaireList: data.questionnare,
              });
            } else
              patchState({
                questionnaireList: response?.data?.questionnare,
              });
          }
        }
      })
    );
  }

  @Action(SkipNewPatientQuestionnaire)
  SkipNewPatientQuestionnaire(
    { patchState }: StateContext<QuestionnaireStateModel>,
    { payload }: any
  ) {
    const token = this.store.selectSnapshot(UserAuthState.getUserAuthDetails)
      .token;
    return this.questionnaireService
      .SkipNewPatientQuestionnaire(
        { isQuestionnaireSkipped: true },
        payload,
        token
      )
      .pipe(
        tap((response) => {
          if (response) {
            patchState({
              questionnaireSkippedResponse: response,
            });
          } else {
            patchState({
              questionnaireSkippedResponse: undefined,
            });
          }
        })
      );
  }
  @Action(AddPatientResponse)
  addPatientResponse(
    { patchState }: StateContext<QuestionnaireStateModel>,
    { payload }: any
  ) {
    const token = this.store.selectSnapshot(UserAuthState.getUserAuthDetails)
      .token;

    if (payload['patientId']) {
      return this.questionnaireService
        .savePatientResponse(payload.data, payload.questionnaireJson, payload['patientId'], token)
        .pipe(
          tap((response) => {
            if (response) {
              patchState({
                answerSaveSuccess: response,
              });
            } else {
              patchState({
                answerSaveSuccess: undefined,
              });
            }
          })
        );
    } else {
      const appointmentResponse = this.store.selectSnapshot(
        AppointmentState.getAppointmentResponse
      );
      const appointmentId = payload['appointmentId']
        ? payload['appointmentId']
        : appointmentResponse.appointmentId;

      return this.questionnaireService
        .saveResponse(payload.data, payload.questionnaireJson, appointmentId, token)
        .pipe(
          tap((response) => {
            if (response) {
              patchState({
                answerSaveSuccess: response,
              });
            } else {
              patchState({
                answerSaveSuccess: undefined,
              });
            }
          })
        );
    }
  }

  @Action(EnableQuestionnaire)
  enableQuestionnaire(
    { patchState }: StateContext<QuestionnaireStateModel>,
    { payload }: any
  ) {
    const token = this.store.selectSnapshot(UserAuthState.getUserAuthDetails)
      .token;
    return this.questionnaireService.enableQuestionnaire(payload, token).pipe(
      tap((response) => {
        if (response && response.statusCode) {
          if (response.statusCode == 200) {
            this.store.dispatch(new FetchAllReasons());
            0;
          }
        }
      })
    );
  }

  @Action(AddQuestionnaire)
  addQuestionnaire(
    { patchState }: StateContext<QuestionnaireStateModel>,
    { payload }: any
  ) {
    const token = this.store.selectSnapshot(UserAuthState.getUserAuthDetails)
      .token;
    return this.questionnaireService.addQuestionnaire(payload, token).pipe(
      tap((response) => {
        if (response && response.statusCode) {
          if (response.statusCode == 200) {
            this.router.navigate['/clinic/physician/questionnaire'];
            this.alert.simpleAlert('', 'Form Saved');
          }
        }
      })
    );
  }

  @Action(UpdateQuestionnaire)
  updateQuestionnaire(
    { patchState }: StateContext<QuestionnaireStateModel>,
    { payload }: any
  ) {
    const token = this.store.selectSnapshot(UserAuthState.getUserAuthDetails)
      .token;
    return this.questionnaireService.updateQuestionnaire(payload, token).pipe(
      tap((response) => {
        if (response && response.statusCode) {
          if (response.statusCode == 200) {
            this.router.navigate(['/clinic/physician/questionnaire']);
            this.alert.simpleAlert('', 'Form Saved');
          }
        }
      })
    );
  }

  @Action(FetchReasonQuestionnaire)
  fetchReasonQuestionnaire(
    { patchState }: StateContext<QuestionnaireStateModel>,
    { payload }: any
  ) {
    const token = this.store.selectSnapshot(UserAuthState.getUserAuthDetails)
      .token;
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    let userDetail = loginInfo;
    if (loginInfo.userRoles.includes('patient')) {
      const selected = this.store.selectSnapshot(
        FamilyMemberState.getSelectedPatient
      );
      if (selected) userDetail = selected;
    } else {
      const selected = this.store.selectSnapshot(
        VirtualCareState.getSelectedPatient
      );
      if (selected) {
        userDetail = selected;
      }
    }
    return this.questionnaireService
      .getReasonQuestionnaire(payload, token)
      .pipe(
        tap((response) => {
          if (response && response.statusCode) {
            if (response.statusCode == 200) {
              if (response.data) {
                const question = response.data.questionnare;
                if (
                  payload == 104 &&
                  userDetail.gender?.itemName.toLowerCase() == 'male' &&
                  question.components &&
                  question.components[0]
                ) {
                  const compo = question.components[0]?.components;
                  if (compo) {
                    compo.forEach((element, index) => {
                      if (element.key == 'femaleSymptoms') {
                        compo.splice(index, 1);
                      }
                    });
                  }
                  patchState({
                    reasonQuestionnaire: response.data,
                  });
                } else
                  patchState({
                    reasonQuestionnaire: response.data ? response.data : null,
                  });
              }
            }
          }
        })
      );
  }
  @Action(ClearAnswerResponse)
  clearAnswerResponse({ patchState }: StateContext<QuestionnaireStateModel>) {
    patchState({
      answerSaveSuccess: null,
    });
  }
  @Action(ClearSkipNewPatientQuestionnaire)
  ClearSkipNewPatientQuestionnaire({
    patchState,
  }: StateContext<QuestionnaireStateModel>) {
    patchState({
      questionnaireSkippedResponse: null,
    });
  }

  @Action(ClearQuestionnaire)
  ClearQuestionnaire({ patchState }: StateContext<QuestionnaireStateModel>) {
    patchState({
      reasonQuestionnaire: null,
      questionnaireList: null,
    });
  }
}
