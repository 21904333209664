import { Component, Injectable } from '@angular/core';
import { PWAService } from '@quipo/quipo-lib';
import { GoogleAnalyticsService } from '../google-analytics.service';
@Component({
  selector: 'quipo-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
@Injectable({
  providedIn: PWAService,
})
export class AppComponent {
  title = 'client-quipo-web';
  cookieExists: any;
  showCookieAlert: any;

  constructor(
    private readonly _$gaService: GoogleAnalyticsService,
    private readonly _$pwaService: PWAService
  ) {
    this._$gaService.init();
    // check the service worker for updates
    this._$pwaService.checkForUpdates();
  }
}
