import { Injectable } from '@angular/core';
import { Action, State, StateContext, Selector, Store } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import {
  ProcessSignUp,
  ProcessLogin,ValidateMagicLink,
  ResendVerification,
  ClearLoginDetals,
  ClearSignupResponse,
  FetchPatientInvoiceList,
  GetBillingPaymentResponse,
  Forgotpassword,
  ClearconfirmCheckIn,
  ClearInvoiceList,
  IntitiateOscarCall,
  FetchToken,
  FetchUserInfo,
  UpdateToken,
  PayInvoiceByCash,
  PayInvoiceByCard,
  ClearForgotPaswdResponse,
  ClearPatientCheckIn,
  ConfirmPatientCheckIn,
  PatientCheckIn,
  ValidatePHN,
  KeycloackSignUp,
  ClearKeycloackSignUpResponse,
  GetKeycloakUserDetails,
  ClearKeycloackUserResponse,
  FetchPendingInvoiceList, FetchProviderToken, ClearProviderToken,
  ResetPassword,
  ClearResetResponse,
  BlockedAccountsList,
  ExecuteUnBlockAccount,
  AccountUnLockUsingOTP,
  ClearMagicLink
} from '../actions/user-auth.actions';
import { ClearProfileData } from '../actions/physician.actions';
import { UserAuthService } from '../services/user-auth.service';
import { SweetAlertService } from '../../../src/services/sweet-alert-service';
import { ActivatedRoute } from '@angular/router';
import {
  LoginInfo,
  userDetails,
  SignUpResponse,
} from '../interfaces/user.interface';
import { FamilyMemberState } from './family-member.state';
import { ClearFamilyMembers } from '../actions';
import { NgxsStoragePluginModule, StorageOption } from '@ngxs/storage-plugin';
export class UserAuthStateModel {
  LoginInfo: LoginInfo;
  userInfo: userDetails;
  signUpResponse: SignUpResponse;
  invoiceList: any[];
  billPaymentResponse: SignUpResponse;
  forgotpaswdResponse: SignUpResponse;
  KeycloaksignUpResponse: SignUpResponse;
  patientCheckIn: any;
  confirmCheckIn: any;
  oscarCall: any;
  // LoginUserInfo: LoginInfo;
  badgeCount: any;
  showChat: boolean;
  phnValidate: any;
  keycloakUserDetails: any;
  pendingInvoices: any[];
  providerToken: any;
  resetResponse: any;
  blockedAccounts: any;
  unBlockAccountStatus: any;
  unBlockAccountUsingOtpStatus: any;
  validateMagicLink: any;
}

@State<UserAuthStateModel>({
  name: 'currentUserDetails',
  defaults: {
    oscarCall: undefined,
    providerToken: undefined,
    signUpResponse: {
      errorMessage: undefined,
      statusCode: 0,
      data: undefined,
    },
    LoginInfo: {
      name: undefined,
      token: undefined,
      userToken: undefined,
      statusCode: 0,
      errorMessage: undefined,
      patientId: undefined,
      staffId: undefined,
      oscarPatientId: undefined,
      email: undefined,
      phoneNo: undefined,
      oscarDoctorId: undefined,
      oscarDoctorName: undefined,
      accountHolderId: undefined,
      isAccountHolder: false,
      userRoles: [],
      photoUrl: undefined,
      isQuestionnaireCompleted: false,
      isQuestionnaireSkipped: false,
      inClinic: 'no',
      gender: undefined,
      isOutStandingInvoice: false,
      isOnBoardedPatient: false,
      isBlockedOnBoarding: false,
      isApprovedOnBoarding: false,
      approvedId: undefined,
      password: undefined,
      signupCompleted:true,
      autoLoginOscar:false
    },
    userInfo: {
      dob: undefined,
      isHealthNoPresent: true,
      personalHealthNo: undefined,
      isOutOfProvince: false,
      OutOfProvinceHealthNo: undefined,
      billingAddress: undefined,
      cardNumber: undefined,
      expiryDate: undefined,
      ccvNumber: undefined,
      firstName: undefined,
      lastName: undefined,
      primaryPhoneNo: undefined,
      secondaryPhoneNo: undefined,
      email: undefined,
      address: undefined,
      password: undefined,
      doctor: undefined,
    },

    invoiceList: [],
    billPaymentResponse: {
      errorMessage: undefined,
      statusCode: 0,
      data: undefined,
    },
    forgotpaswdResponse: {
      errorMessage: undefined,
      statusCode: 0,
      data: undefined,
    },
    KeycloaksignUpResponse: {
      errorMessage: undefined,
      statusCode: 0,
      data: undefined,
    },
    patientCheckIn: undefined,
    confirmCheckIn: undefined,
    badgeCount: undefined,
    showChat: false,
    phnValidate: undefined,
    keycloakUserDetails: undefined,
    pendingInvoices: undefined,
    resetResponse: undefined,
    blockedAccounts: undefined,
    unBlockAccountStatus: undefined,
    unBlockAccountUsingOtpStatus: undefined,
    validateMagicLink: undefined,
  },


})
@Injectable()
export class UserAuthState {
  constructor(
    private userAuthService: UserAuthService,
    private store: Store,
    private router: Router,
    private alert: SweetAlertService,
    private route: ActivatedRoute
  ) { }
  @Selector()
  static getOscarCall(state: UserAuthStateModel) {
    return state.oscarCall;
  }

  // Billing section
  @Selector()
  static getBillingInvoiceList(state: UserAuthStateModel) {
    return state.invoiceList;
  }

  @Selector()
  static getBillPaymentResponse(state: UserAuthStateModel) {
    return state.billPaymentResponse;
  }
  @Selector()
  static getproviderToken(state: UserAuthStateModel) {
    return state.providerToken;
  }
  @Selector()
  static getUserAuthDetails(state: UserAuthStateModel) {
    return state.LoginInfo;
  }
  @Selector()
  static getBadgeCount(state: UserAuthStateModel) {
    return state.badgeCount;
  }
  // @Selector()
  // static getLoginUserDetails(state: UserAuthStateModel) {
  //   return state.LoginUserInfo;
  // }

  @Selector()
  static getSignUpResponse(state: UserAuthStateModel) {
    return state.signUpResponse;
  }
  @Selector()
  static getForgotPaswdResponse(state: UserAuthStateModel) {
    return state.forgotpaswdResponse;
  }

  @Selector()
  static getChatStatus(state: UserAuthStateModel) {
    return state.showChat;
  }

  @Selector()
  static getPatientCheckInResponse(state: UserAuthStateModel) {
    return state.patientCheckIn;
  }

  @Selector()
  static getconfirmCheckInResponse(state: UserAuthStateModel) {
    return state.confirmCheckIn;
  }
  @Selector()
  static getPHNValidation(state: UserAuthStateModel) {
    return state.phnValidate;
  }
  @Selector()
  static getKeycloackResponse(state: UserAuthStateModel) {
    return state.KeycloaksignUpResponse;
  }
  @Selector()
  static getKeycloackUserResponse(state: UserAuthStateModel) {
    return state.keycloakUserDetails;
  }
  @Selector()
  static getPendingInvoices(state: UserAuthStateModel) {
    return state.pendingInvoices;
  }
  @Selector()
  static getResetResponse(state: UserAuthStateModel) {
    return state.resetResponse;
  }
  @Selector()
  static getBlockedAccounts(state: UserAuthStateModel) {
    return state.blockedAccounts;
  }
  @Selector()
  static getUnBlockAccountStatus(state: UserAuthStateModel) {
    return state.unBlockAccountStatus;
  }
  @Selector()
  static getunBlockAccountUsingOtpStatuss(state: UserAuthStateModel) {
    return state.unBlockAccountUsingOtpStatus;
  }
  @Selector()
  static getvalidateMagicLink(state: UserAuthStateModel) {
    return state.validateMagicLink;
  }
  
  @Action(ClearForgotPaswdResponse)
  ClearForgotPaswdResponse({ patchState }: StateContext<UserAuthStateModel>) {
    patchState({
      forgotpaswdResponse: {
        errorMessage: undefined,
        statusCode: 0,
        data: undefined,
      },
    });
  }
  @Action(ClearSignupResponse)
  ClearSignupResponse({ patchState }: StateContext<UserAuthStateModel>) {
    patchState({
      signUpResponse: {
        errorMessage: undefined,
        statusCode: 0,
        data: undefined,
      },
    });
  }
  @Action(ClearKeycloackSignUpResponse)
  ClearKeycloackSignUpResponse({
    patchState,
  }: StateContext<UserAuthStateModel>) {
    patchState({
      KeycloaksignUpResponse: {
        errorMessage: undefined,
        statusCode: 0,
        data: undefined,
      },
    });
  }
  @Action(ClearLoginDetals)
  ClearLoginDetals({ patchState }: StateContext<UserAuthStateModel>) {
    patchState({
      LoginInfo: {
        name: undefined,
        token: undefined,
        userToken: undefined,
        statusCode: 0,
        errorMessage: undefined,
        patientId: undefined,
        staffId: undefined,
        oscarPatientId: undefined,
        email: undefined,
        phoneNo: undefined,
        oscarDoctorId: undefined,
        oscarDoctorName: undefined,
        accountHolderId: undefined,
        isAccountHolder: false,
        userRoles: [],
        photoUrl: undefined,
        isQuestionnaireCompleted: false,
        isQuestionnaireSkipped: false,
        inClinic: 'no',
        gender: undefined,
        isOutStandingInvoice: false,
        isOnBoardedPatient: false,
        isApprovedOnBoarding: false,
        approvedId: undefined,
        isBlockedOnBoarding: false,
        password: undefined,
        signupCompleted: undefined,
        autoLoginOscar: undefined
      },
      badgeCount: undefined,
    });
    this.store.dispatch(new ClearFamilyMembers());
  }
  @Action(ClearMagicLink)
  ClearMagicLink({ patchState }: StateContext<UserAuthStateModel>) {
    patchState({
      validateMagicLink: undefined
    });
   
  }

  @Action(ValidateMagicLink)
  ValidateMagicLink(
    { patchState }: StateContext<UserAuthStateModel>,
    { payload }: ValidateMagicLink
  ) {
    return this.userAuthService.ValidateMagicLink(payload).pipe(
      tap((response: any) => {        
        if (response && response.statusCode) {
          if (response.statusCode == 200) { 
            if (response.data) {
              patchState({
                validateMagicLink: response.data.email
              });
              return;
            }
           
          } 
        }
        patchState({
          validateMagicLink: undefined
        });
      })
    );
  }

  @Action(ProcessLogin)
  ProcessLogin(
    { patchState }: StateContext<UserAuthStateModel>,
    { payload }: ProcessLogin
  ) {
    payload.username = payload.username.toLowerCase();
    return this.userAuthService.loginUser(payload).pipe(
      tap((response: any) => {
        if (response && response.statusCode) {
          if (response.statusCode == 200) {
            //if (response.data && response.data.token && response.data.name) {
            if (response.data) {
              // if (response.data.chatAppId)
              //   localStorage.setItem('isChatEnabled', 'true');

              //if (response.data.patientId) {
              //if (response.data.roles.includes('patient')) {
              if (response.data.userRoles.includes('patient')) {
                const emailFromSessionStorage = sessionStorage.getItem('email');
                const patientIdFromSessionStorage = sessionStorage.getItem('patientId');
                sessionStorage.clear();
                sessionStorage.setItem('loggedUserToken', response.data.token);
                if (emailFromSessionStorage) {
                  sessionStorage.setItem('email', emailFromSessionStorage);
                }
                
                if (patientIdFromSessionStorage) {
                  sessionStorage.setItem('patientId', patientIdFromSessionStorage);
                }
                this.store.dispatch(new ClearLoginDetals());
              } else {
                sessionStorage.clear();
                const pwa = localStorage.getItem('hideInstall');
                localStorage.clear();
                if (pwa) localStorage.setItem('hideInstall', pwa);
                localStorage.setItem('loggedUserToken', response.data.token);
                this.store.dispatch(new ClearLoginDetals());
                this.store.dispatch(new ClearProfileData());
              }

              patchState({
                LoginInfo: {
                  name: response.data.name,
                  token: response.data.token,
                  userToken: response.data.token,
                  patientId: response.data.patientId,
                  oscarPatientId: response.data.oscarPatientId,
                  email: response.data.email,
                  phoneNo: response.data.phone,
                  oscarDoctorId: response.data.oscarDoctorId,
                  oscarDoctorName: response.data.oscarDoctorName,
                  accountHolderId: response.data.accountHolderId,
                  isAccountHolder: response.data.isAccountHolder,
                  userRoles: response.data.userRoles,
                  staffId: response.data.staffId,
                  statusCode: 200,
                  errorMessage: undefined,
                  photoUrl: response.data.photoUrl,
                  isQuestionnaireCompleted:
                    response.data.isQuestionnaireCompleted,
                  isQuestionnaireSkipped: response.data.isQuestionnaireSkipped,
                  inClinic: payload.inClinic,
                  gender: response.data.gender,
                  isOutStandingInvoice: response.data.isOutStandingInvoice,
                  isOnBoardedPatient: response.data.isOnBoardedPatient,
                  isApprovedOnBoarding: response.data.isApprovedOnBoarding,
                  approvedId: response.data.approvedId,
                  isBlockedOnBoarding: response.data.isBlockedOnBoarding,
                  userId: response.data.userId,
                  keycloakrefId: response.data.keyClockReferenceId,
                  password: response.data.password,
                  signupCompleted: response.data.signupCompleted,
                  autoLoginOscar: response.data.autoLoginOscar
                },
                badgeCount: undefined,
                showChat: response.data.chatAppId ? true : false,
              });
            }
          } else {
            patchState({
              LoginInfo: {
                name: undefined,
                token: undefined,
                userToken: undefined,
                patientId: undefined,
                oscarPatientId: undefined,
                email: undefined,
                phoneNo: undefined,
                oscarDoctorId: undefined,
                oscarDoctorName: undefined,
                accountHolderId: undefined,
                isAccountHolder: false,
                userRoles: [],
                staffId: undefined,
                statusCode: response.statusCode,
                errorMessage: response.message ? response.message : 'Error',
                photoUrl: undefined,
                isQuestionnaireCompleted: false,
                isQuestionnaireSkipped: false,
                inClinic: 'no',
                gender: undefined,
                isOutStandingInvoice: false,
                isOnBoardedPatient: false,
                isApprovedOnBoarding: false,
                approvedId: undefined,
                isBlockedOnBoarding: false,
                userId: undefined,
                keycloakrefId: undefined,
                password: undefined,
                signupCompleted: undefined,
                autoLoginOscar: undefined
              },

              badgeCount: undefined,
              showChat: false,
            });
          }
        } else {
          patchState({
            LoginInfo: {
              name: undefined,
              token: undefined,
              userToken: undefined,
              statusCode: 0,
              errorMessage: undefined,
              patientId: undefined,
              oscarPatientId: undefined,
              email: undefined,
              phoneNo: undefined,
              oscarDoctorId: undefined,
              oscarDoctorName: undefined,
              accountHolderId: undefined,
              isAccountHolder: false,
              staffId: undefined,
              userRoles: [],
              photoUrl: undefined,
              isQuestionnaireCompleted: false,
              isQuestionnaireSkipped: false,
              inClinic: 'no',
              gender: undefined,
              isOutStandingInvoice: false,
              isOnBoardedPatient: false,
              isApprovedOnBoarding: false,
              approvedId: undefined,
              isBlockedOnBoarding: false,
              userId: undefined,
              keycloakrefId: undefined,
              password: undefined,
              signupCompleted: undefined,
              autoLoginOscar: undefined
            },

            badgeCount: undefined,
            showChat: false,
          });
        }
      })
    );
  }
  @Action(ResendVerification)
  ResendVerification(
    { patchState }: StateContext<UserAuthStateModel>,
    { payload }: ResendVerification
  ) {  
    return this.userAuthService.resendOTP(payload).pipe(
      tap((response: any) => {  
        if (response && response.statusCode) {
          if (response.statusCode == 200) {
            this.alert.simpleAlert('Sign up', 'One Time Passcode resent successfully!');
          }
        }
      })
    );
  }

  @Action(AccountUnLockUsingOTP)
  AccountUnLockUsingOTP(
    { patchState }: StateContext<UserAuthStateModel>,
    { payload }: AccountUnLockUsingOTP
  ) {
    return this.userAuthService.accountUnlockUsingOtp(payload).pipe(
      tap((response: any) => {

        if (response && response.statusCode) {
          if (response.statusCode == 200 && response.data) {
            patchState({
              unBlockAccountUsingOtpStatus: {
                status: response.data,
                errorMessage: undefined
              }
            });
          } else {
            patchState({
              unBlockAccountUsingOtpStatus: {
                status: false,
                errorMessage: response.message ? response.message : 'Error',
              },
            });
          }
        }

        console.log(response,'res');
      })
    );
  }

  @Action(ProcessSignUp)
  ProcessSignUp(
    { patchState }: StateContext<UserAuthStateModel>,
    { payload }: ProcessSignUp
  ) {
    payload.email = payload.email.toLowerCase();
    return this.userAuthService.signUpUser(payload).pipe(
      tap((response: any) => {
        if (response && response.statusCode) {
          if (response.statusCode == 200) {
            if (response.data) {
              if (response.data.userRoles.includes('patient')) {
                sessionStorage.clear();
                sessionStorage.setItem('loggedUserToken', response.data.token);
                this.store.dispatch(new ClearLoginDetals());
              } else {
                sessionStorage.clear();
                const pwa = localStorage.getItem('hideInstall');
                localStorage.clear();
                if (pwa) localStorage.setItem('hideInstall', pwa);
                localStorage.setItem('loggedUserToken', response.data.token);
                this.store.dispatch(new ClearLoginDetals());
                this.store.dispatch(new ClearProfileData());
              }
            }
            patchState({
              signUpResponse: {
                errorMessage: undefined,
                statusCode: 200,
                data: response.data,
              },
              LoginInfo: {
                name: response.data.name,
                token: response.data.token,
                userToken: response.data.token,
                patientId: response.data.patientId,
                oscarPatientId: response.data.oscarPatientId,
                email: response.data.email,
                phoneNo: response.data.phone,
                oscarDoctorId: response.data.oscarDoctorId,
                oscarDoctorName: response.data.oscarDoctorName,
                accountHolderId: response.data.accountHolderId,
                isAccountHolder: response.data.isAccountHolder,
                userRoles: response.data.userRoles,
                staffId: response.data.staffId,
                statusCode: 200,
                errorMessage: undefined,
                photoUrl: response.data.photoUrl,
                isQuestionnaireCompleted:
                  response.data.isQuestionnaireCompleted,
                isQuestionnaireSkipped: response.data.isQuestionnaireSkipped,
                inClinic: payload.inClinic,
                gender: response.data.gender,
                isOutStandingInvoice: response.data.isOutStandingInvoice,
                isOnBoardedPatient: response.data.isOnBoardedPatient,
                isApprovedOnBoarding: response.data.isApprovedOnBoarding,
                approvedId: response.data.approvedId,
                isBlockedOnBoarding: response.data.isBlockedOnBoarding,
                userId: response.data.userId,
                keycloakrefId: response.data.keyClockReferenceId,
                password: response.data.password,
                signupCompleted: response.data.signupCompleted,
                autoLoginOscar: response.data.autoLoginOscar,
              },
              badgeCount: undefined,
              showChat: response.data.chatAppId ? true : false,
            });
          } else {
            patchState({
              signUpResponse: {
                statusCode: response.statusCode,
                errorMessage: response.message ? response.message : 'Error',
                data: undefined,
              },
              LoginInfo: {
                name: undefined,
                token: undefined,
                userToken: undefined,
                patientId: undefined,
                oscarPatientId: undefined,
                email: undefined,
                phoneNo: undefined,
                oscarDoctorId: undefined,
                oscarDoctorName: undefined,
                accountHolderId: undefined,
                isAccountHolder: false,
                userRoles: [],
                staffId: undefined,
                statusCode: response.statusCode,
                errorMessage: response.message ? response.message : 'Error',
                photoUrl: undefined,
                isQuestionnaireCompleted: false,
                isQuestionnaireSkipped: false,
                inClinic: 'no',
                gender: undefined,
                isOutStandingInvoice: false,
                isOnBoardedPatient: false,
                isApprovedOnBoarding: false,
                approvedId: undefined,
                isBlockedOnBoarding: false,
                userId: undefined,
                keycloakrefId: undefined,
                password: undefined,
                signupCompleted: undefined,
                autoLoginOscar: undefined,
              },

              badgeCount: undefined,
              showChat: false,
            });
          }
        } else {
          patchState({
            signUpResponse: {
              statusCode: 0,
              errorMessage: undefined,
              data: undefined,
            },
            LoginInfo: {
              name: undefined,
              token: undefined,
              userToken: undefined,
              patientId: undefined,
              oscarPatientId: undefined,
              email: undefined,
              phoneNo: undefined,
              oscarDoctorId: undefined,
              oscarDoctorName: undefined,
              accountHolderId: undefined,
              isAccountHolder: false,
              userRoles: [],
              staffId: undefined,
              statusCode: response.statusCode,
              errorMessage: response.message ? response.message : 'Error',
              photoUrl: undefined,
              isQuestionnaireCompleted: false,
              isQuestionnaireSkipped: false,
              inClinic: 'no',
              gender: undefined,
              isOutStandingInvoice: false,
              isOnBoardedPatient: false,
              isApprovedOnBoarding: false,
              approvedId: undefined,
              isBlockedOnBoarding: false,
              userId: undefined,
              keycloakrefId: undefined,
              password: undefined,
              signupCompleted: undefined,
              autoLoginOscar: undefined,
            },

            badgeCount: undefined,
            showChat: false,
          });
        }
      })
    );
  }

  // Fetching patient's invoice list
  @Action(FetchPatientInvoiceList)
  FetchRecentHealthSummary(
    { patchState }: StateContext<UserAuthStateModel>,
    { payload }: any
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    // const patient = this.store.selectSnapshot(
    //   FamilyMemberState.getSelectedPatient
    // );
    if (loginInfo && loginInfo.token) {
      return this.userAuthService
        .fetchBillingInvoiceList(loginInfo.token, payload)
        .pipe(
          tap((response: any) => {
            if (response && response.statusCode) {
              if (response.statusCode == 200) {
                if (response.data) {
                  patchState({
                    invoiceList: response.data,
                  });
                  return;
                }
              }
              patchState({
                invoiceList: [],
              });
            }
          })
        );
    }
  }

  // Clear Patient's invoice list
  @Action(ClearInvoiceList)
  ClearCareteamsList({ patchState }: StateContext<UserAuthStateModel>) {
    patchState({
      invoiceList: undefined,
    });
  }



  // Get payment response
  @Action(GetBillingPaymentResponse)
  BillPaymentResponse(
    { patchState }: StateContext<UserAuthStateModel>,
    { payload }: any
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    if (loginInfo && loginInfo.token) {
      return this.userAuthService
        .getBillingPaymentResponse(loginInfo.token, payload)
        .pipe(
          tap((response: any) => {
            if (response && response.statusCode) {
              if (response.statusCode == 200) {
                patchState({
                  billPaymentResponse: {
                    errorMessage: undefined,
                    statusCode: 200,
                    data: undefined,
                  },
                });
              } else {
                patchState({
                  billPaymentResponse: {
                    statusCode: response.statusCode,
                    errorMessage: response.message ? response.message : 'Error',
                    data: undefined,
                  },
                });
              }
            } else {
              patchState({
                billPaymentResponse: {
                  statusCode: 0,
                  errorMessage: undefined,
                  data: undefined,
                },
              });
            }
          })
        );
    }
  }
  @Action(Forgotpassword)
  Forgotpassword(
    { patchState }: StateContext<UserAuthStateModel>,
    { payload }: Forgotpassword
  ) {
    return this.userAuthService.forgotpasswordMail(payload).pipe(
      tap((response: any) => {
        if (response && response.statusCode) {
          if (response.statusCode == 200) {
            patchState({
              forgotpaswdResponse: {
                errorMessage: undefined,
                statusCode: 200,
                data: undefined,
              },
            });
          } else {
            patchState({
              forgotpaswdResponse: {
                statusCode: response.statusCode,
                errorMessage: response.message ? response.message : 'Error',
                data: undefined,
              },
            });
          }
        } else {
          patchState({
            forgotpaswdResponse: {
              statusCode: 0,
              errorMessage: undefined,
              data: undefined,
            },
          });
        }
      })
    );
  }
  @Action(IntitiateOscarCall)
  IntitiateOscarCall(
    { patchState }: StateContext<UserAuthStateModel>,
    { payload }: any
  ) {
    // this.spinner.show();
    return this.userAuthService.initiateOscarCall(payload).pipe(
      tap((response: any) => {
        // this.spinner.hide();
        if (response && response.statusCode) {
          const loginInfo = this.store.selectSnapshot(
            UserAuthState.getUserAuthDetails
          );

          if (response.statusCode == 200) {
            if (!loginInfo || (loginInfo && !loginInfo.staffId)) {
              patchState({
                LoginInfo: {
                  name: response.data.name,
                  userToken: response.data.token,
                  token: response.data.token,
                  patientId: response.data.patientId,
                  oscarPatientId: response.data.oscarPatientId,
                  email: response.data.email,
                  phoneNo: response.data.phone,
                  oscarDoctorId: response.data.oscarDoctorId,
                  oscarDoctorName: response.data.oscarDoctorName,
                  accountHolderId: response.data.accountHolderId,
                  isAccountHolder: response.data.isAccountHolder,
                  userRoles: response.data.userRoles,
                  staffId: response.data.staffId,
                  statusCode: 200,
                  errorMessage: undefined,
                  photoUrl: response.data.photoUrl,
                  isQuestionnaireCompleted:
                    response.data.isQuestionnaireCompleted,
                  isQuestionnaireSkipped: response.data.isQuestionnaireSkipped,
                  inClinic: 'no',
                  gender: response.data.gender,
                  isOutStandingInvoice: response.data.isOutStandingInvoice,
                  isOnBoardedPatient: response.data.isOnBoardedPatient,
                  isApprovedOnBoarding: response.data.isApprovedOnBoarding,
                  approvedId: response.data.approvedId,
                  isBlockedOnBoarding: response.data.isBlockedOnBoarding,
                  userId: response.data.userId ? response.data.userId : '',
                  password: undefined,
                  signupCompleted: undefined,
                  autoLoginOscar: undefined,
                },
                badgeCount: undefined,
                oscarCall: response.data,
              });
            }
          } else {
            patchState({
              badgeCount: undefined,
              oscarCall: undefined,
            });
            patchState({
              badgeCount: undefined,
              LoginInfo: undefined,
            });
          }
        } else {
          patchState({
            badgeCount: undefined,
            oscarCall: undefined,
          });
          patchState({
            badgeCount: undefined,
            LoginInfo: undefined,
          });
        }
      })
    );
  }

  @Action(FetchToken)
  fetchUserToken({ patchState }: StateContext<UserAuthStateModel>) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    if (loginInfo && loginInfo.token) {
      const expiry = JSON.parse(atob(loginInfo.token.split('.')[1])).exp;
      if (Math.floor(new Date().getTime() / 1000) >= expiry) {
        this.store.dispatch(new ClearLoginDetals());
        this.alert.toast(
          'Session Timeout',
          'Session is expired. Please login again.'
        );
        this.router.navigate(['/']);
      } else {
        return this.userAuthService.getToken(loginInfo.token).pipe(
          tap((response: any) => {
            if (response && response.statusCode) {
              if (response.statusCode == 200) {
                loginInfo.token = response.data?.token;
              } else {
                loginInfo.token = undefined;

                this.router.navigate(['/']);
              }
              let badges = undefined;
              if (response.data) {
                badges = {
                  message: response.data.unreadMessageCount
                    ? response.data.unreadMessageCount
                    : 0,
                  pending: response.data.pending ? response.data.pending : 0,
                  upcoming: response.data.upcoming ? response.data.upcoming : 0,
                };
              }
              patchState({
                badgeCount: badges,
                LoginInfo: loginInfo,
              });
            }
          })
        );
      }
    }
  }

  @Action(FetchUserInfo)
  fetchUserInfo(
    { patchState }: StateContext<UserAuthStateModel>,
    { payload }: any
  ) {
    return this.userAuthService.getUserInfo(payload.token).pipe(
      tap((response: any) => {
        if (response && response.statusCode) {
          if (response.statusCode == 200) {
            const userResponseData = response.data;
            userResponseData['userToken'] = payload.token;
            userResponseData['token'] = payload.token;
            userResponseData['staffId'] = userResponseData.staffId;
            userResponseData['statusCode'] = 200;
            userResponseData['errorMessage'] = undefined;
            this.store.dispatch(new UpdateToken(userResponseData));
          } else {
            patchState({
              LoginInfo: {
                name: undefined,
                token: undefined,
                userToken: undefined,
                patientId: undefined,
                oscarPatientId: undefined,
                email: undefined,
                phoneNo: undefined,
                oscarDoctorId: undefined,
                oscarDoctorName: undefined,
                accountHolderId: undefined,
                isAccountHolder: false,
                userRoles: [],
                staffId: undefined,
                statusCode: response.statusCode,
                errorMessage: response.message ? response.message : 'Error',
                photoUrl: undefined,
                isQuestionnaireCompleted: false,
                isQuestionnaireSkipped: false,
                inClinic: 'no',
                gender: undefined,
                isOutStandingInvoice: false,
                isOnBoardedPatient: false,
                isApprovedOnBoarding: false,
                approvedId: undefined,
                isBlockedOnBoarding: false,
                userId: undefined,
                keycloakrefId: undefined,
                password: undefined,
                signupCompleted: undefined,
                autoLoginOscar: undefined,
              },
              badgeCount: undefined,
            });
          }
        }
      })
    );
  }

  @Action(UpdateToken)
  updateToken(
    { patchState }: StateContext<UserAuthStateModel>,
    { payload }: any
  ) {
    return this.userAuthService.getToken(payload.userToken).pipe(
      tap((response: any) => {
        if (response && response.statusCode) {
          if (response.statusCode == 200) {
            patchState({
              LoginInfo: {
                name: payload.name,
                userToken: payload.token,
                token: response.data?.token,
                patientId: payload.patientId,
                oscarPatientId: payload.oscarPatientId,
                email: payload.email,
                phoneNo: payload.phone,
                oscarDoctorId: payload.oscarDoctorId,
                oscarDoctorName: payload.oscarDoctorName,
                accountHolderId: payload.accountHolderId,
                isAccountHolder: payload.isAccountHolder,
                userRoles: payload.userRoles,
                staffId: payload.staffId,
                statusCode: 200,
                errorMessage: undefined,
                photoUrl: payload.photoUrl,
                isQuestionnaireCompleted: payload.isQuestionnaireCompleted,
                isQuestionnaireSkipped: payload.isQuestionnaireSkipped,
                inClinic: payload.inClinic,
                gender: payload.gender,
                isOutStandingInvoice: payload.isOutStandingInvoice,
                isOnBoardedPatient: payload.isOnBoardedPatient,
                isApprovedOnBoarding: payload.isApprovedOnBoarding,
                approvedId: payload.approvedId,
                isBlockedOnBoarding: payload.isBlockedOnBoarding,
                userId: payload.userId ? payload.userId : '',
                password: undefined,
                signupCompleted: undefined,
                autoLoginOscar: undefined,
              },
              badgeCount: payload.badgeCount ? payload.badgeCount : undefined,
            });
          } else {
            patchState({
              LoginInfo: {
                name: undefined,
                token: undefined,
                userToken: undefined,
                patientId: undefined,
                oscarPatientId: undefined,
                email: undefined,
                phoneNo: undefined,
                oscarDoctorId: undefined,
                oscarDoctorName: undefined,
                accountHolderId: undefined,
                isAccountHolder: false,
                userRoles: [],
                staffId: undefined,
                statusCode: response.statusCode,
                errorMessage: response.message ? response.message : 'Error',
                photoUrl: undefined,
                isQuestionnaireCompleted: false,
                isQuestionnaireSkipped: false,
                inClinic: 'no',
                gender: undefined,
                isOutStandingInvoice: false,
                isOnBoardedPatient: false,
                isApprovedOnBoarding: false,
                approvedId: undefined,
                isBlockedOnBoarding: false,
                userId: undefined,
                password: undefined,
                signupCompleted: undefined,
                autoLoginOscar: undefined,
              },
              badgeCount: undefined,
            });
          }
        }
      })
    );
  }

  @Action(PayInvoiceByCash)
  payInvoiceByCash(
    { patchState }: StateContext<UserAuthStateModel>,
    { payload }: any
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    return this.userAuthService.payInvoiceByCash(loginInfo.token, payload).pipe(
      tap((response) => {
       // console.log('Response', response);
      })
    );
  }

  @Action(PayInvoiceByCard)
  payInvoiceByStripeCard(
    { patchState }: StateContext<UserAuthStateModel>,
    { payload }: any
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    return this.userAuthService
      .payInvoiceByStripeCard(loginInfo.token, payload)
      .pipe(
        tap((response) => {
          //console.log('Response', response);
        })
      );
  }

  @Action(ConfirmPatientCheckIn)
  ConfirmPatientCheckIn(
    { patchState }: StateContext<UserAuthStateModel>,
    { payload }: any
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    return this.userAuthService
      .confirmPatientCheckIn(loginInfo.token, payload)
      .pipe(
        tap((response: any) => {
          if (response && response.statusCode) {
            if (response.statusCode == 200) {
              patchState({
                confirmCheckIn: response,
              });
            } else {
              patchState({
                confirmCheckIn: undefined,
              });
            }
          } else {
            patchState({
              confirmCheckIn: undefined,
            });
          }
        })
      );
  }

  @Action(PatientCheckIn)
  PatientCheckIn(
    { patchState }: StateContext<UserAuthStateModel>,
    { payload }: any
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    return this.userAuthService.patientCheckIn(loginInfo.token, payload).pipe(
      tap((response: any) => {
        if (response && response.statusCode) {
          if (response.statusCode == 200) {
            patchState({
              patientCheckIn: response,
            });
          } else {
            patchState({
              patientCheckIn: undefined,
            });
          }
        } else {
          patchState({
            patientCheckIn: undefined,
          });
        }
      })
    );
  }

  @Action(ClearPatientCheckIn)
  ClearPatientCheckIn({ patchState }: StateContext<UserAuthStateModel>) {
    patchState({
      patientCheckIn: undefined,
    });
  }

  @Action(ClearconfirmCheckIn)
  ClearconfirmCheckIn({ patchState }: StateContext<UserAuthStateModel>) {
    patchState({
      confirmCheckIn: undefined,
    });
  }

  @Action(ValidatePHN)
  validatePhnDetails(
    { patchState }: StateContext<UserAuthStateModel>,
    { payload }: any
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    return this.userAuthService.validatePHN(loginInfo.token, payload).pipe(
      tap((response: any) => {
        if (response && response.statusCode) {
          if (response.statusCode == 200) {
            patchState({
              phnValidate: response,
            });
          } else {
            patchState({
              phnValidate: undefined,
            });
          }
        } else {
          patchState({
            phnValidate: undefined,
          });
        }
      })
    );
  }
  @Action(KeycloackSignUp)
  KeycloackSignUp(
    { patchState }: StateContext<UserAuthStateModel>,
    { payload }: KeycloackSignUp
  ) {
    payload.email = payload.email.toLowerCase();
    return this.userAuthService.signUpKeyclockUser(payload).pipe(
      tap((response: any) => {
        if (response && response.statusCode) {
          if (response.statusCode == 200) {
            patchState({
              KeycloaksignUpResponse: {
                errorMessage: undefined,
                statusCode: 200,
                data: undefined,
              },
            });
          } else {
            patchState({
              KeycloaksignUpResponse: {
                statusCode: response.statusCode,
                errorMessage: response.message ? response.message : 'Error',
                data: undefined,
              },
            });
          }
        } else {
          patchState({
            KeycloaksignUpResponse: {
              statusCode: 0,
              errorMessage: undefined,
              data: undefined,
            },
          });
        }
      })
    );
  }
  @Action(GetKeycloakUserDetails)
  GetKeycloakUserDetails(
    { patchState }: StateContext<UserAuthStateModel>,
    { payload }: any
  ) {
    return this.userAuthService.getKeycloakUserDetails(payload).pipe(
      tap((response: any) => {
        if (response && response.statusCode) {
          if (response.statusCode == 200) {
            if (response.data) {
              patchState({
                keycloakUserDetails: response.data,
              });
              return;
            }
          }
          patchState({
            keycloakUserDetails: [],
          });
        }
      })
    );
  }
  @Action(ClearKeycloackUserResponse)
  ClearKeycloackUserResponse({ patchState }: StateContext<UserAuthStateModel>) {
    patchState({
      keycloakUserDetails: undefined,
    });
  }

  
  @Action(BlockedAccountsList)
  BlockedAccountsList(
    { patchState }: StateContext<UserAuthStateModel>,
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );

    if (loginInfo && loginInfo.token) {
      return this.userAuthService
        .blockedAccountsList(loginInfo.token)
        .pipe(
          tap((response: any) => {
            console.log('BlockedAccountsList',response);
            if (response && response.statusCode) {
              if (response.statusCode == 200 && response.data) {
                  patchState({
                    blockedAccounts: response.data,
                  });
              }else{
                patchState({
                  blockedAccounts: [],
                });
              }
            }
          })
        );
    }
  }

  @Action(ExecuteUnBlockAccount)
  ExecuteUnBlockAccount(
    { patchState }: StateContext<UserAuthStateModel>,
    { payload }: any
  ) {
    console.log(payload);
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    if (loginInfo && loginInfo.token) {
      return this.userAuthService.executeUnBlockAccount(loginInfo.token,payload)
        .pipe(
          tap((response: any) => {
            console.log(response);

            if (response && response.statusCode) {
              if (response.statusCode == 200 && response.data) {
                  patchState({
                    unBlockAccountStatus: response.data,
                  });
              }else{
                patchState({
                  unBlockAccountStatus: false,
                });
              }
            }

          })
        );
    }
  }

  //Fetch all pending invoices in the account

  @Action(FetchPendingInvoiceList)
  FetchPendingInvoices(
    { patchState }: StateContext<UserAuthStateModel>,
    { payload }: any
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );

    if (loginInfo && loginInfo.token) {
      return this.userAuthService
        .fetchPendingInvoiceList(loginInfo.token, payload)
        .pipe(
          tap((response: any) => {
            if (response && response.statusCode) {
              if (response.statusCode == 200) {
                if (response.data) {
                  patchState({
                    pendingInvoices: response.data,
                  });
                  return;
                }
              }
              patchState({
                pendingInvoices: [],
              });
            }
          })
        );
    }
  }


  @Action(ClearProviderToken)
  ClearProviderToken({ patchState }: StateContext<UserAuthStateModel>) {
    patchState({
      providerToken: undefined,
    });
  }

  // Get payment response
  @Action(FetchProviderToken)
  fetchProviderToken(
    { patchState }: StateContext<UserAuthStateModel>,
    { payload }: any
  ) {

    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    if (loginInfo && loginInfo.token) {
      return this.userAuthService
        .getProviderToken(loginInfo.token, payload)
        .pipe(
          tap((response: any) => {
            if (response && response.statusCode && response.statusCode == 200 && response.data) {
              patchState({
                providerToken: response.data
              });

            } else {
              patchState({
                providerToken: undefined,
              });
            }
          })
        );
    }
  }

  @Action(ResetPassword)
  resetPassword(
    { patchState }: StateContext<UserAuthStateModel>,
    { payload }: any
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    if (loginInfo && loginInfo.token) {
      return this.userAuthService.resetPassword(payload)
        .pipe(
          tap((response: any) => {
            patchState({
              resetResponse: response
            })
          })
        );
    }
  }
  @Action(ClearResetResponse)
  clearResetResponse(
    { patchState }: StateContext<UserAuthStateModel>,
  ) {
    patchState({
      resetResponse: undefined
    })
  }
}
