import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import * as moment from 'moment';
import { tap } from 'rxjs/operators';
import { SweetAlertService } from '../../services';
import {
  AddPatientBill,
  ClearBillingResponse,
  DeletePatientBill,
  FetchBillsByDate,
  FetchChargeLookup,
  FetchClinicCharges,
  FetchPatientList,
  SetClinicCharges,
  FetchBillsByDateFromOscar,
} from '../actions';
import { BillingService } from '../services/billing.service';
import { UserAuthState } from './user-auth.state';
import { RefundPayment } from '../actions/billing.action';
import { NgxSpinnerService } from 'ngx-spinner';

export interface BillingResponse {
  statusCode: number;
  responseMessage: string;
}
export class BillingStateModel {
  bills: any;
  billingResponse: BillingResponse;
  chargesLookUp: any;
  clinicCharges: any;
  patients: any;
  pdfFile: any;
}

@State<BillingStateModel>({
  name: 'billing',
  defaults: {
    bills: undefined,
    chargesLookUp: undefined,
    clinicCharges: undefined,
    patients: undefined,
    pdfFile: undefined,
    billingResponse: {
      responseMessage: undefined,
      statusCode: undefined,
    },
  },
})
@Injectable()
export class BillingState {
  constructor(
    private billingService: BillingService,
    public store: Store,
    public alert: SweetAlertService,
    public spinner: NgxSpinnerService,
  ) {}

  @Selector()
  static getChargesLookup(state: BillingStateModel) {
    return state.chargesLookUp;
  }
  @Selector()
  static getClinicCharges(state: BillingStateModel) {
    return state.clinicCharges;
  }
  @Selector()
  static getBills(state: BillingStateModel) {
    return state.bills;
  }

  @Selector()
  static getAddBillResponse(state: BillingStateModel) {
    return state.billingResponse;
  }

  @Selector()
  static getPatients(state: BillingStateModel) {
    return state.patients;
  }

  @Selector()
  static getPdfFile(state: BillingStateModel) {
    return state.pdfFile;
  }

  @Action(FetchBillsByDate)
  fetchBills(
    { patchState }: StateContext<BillingStateModel>,
    { payload }: any
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    return this.billingService.fetchBillsByDate(payload, loginInfo.token).pipe(
      tap((response) => {
        if (payload.date && payload.date.from) {
          sessionStorage.setItem(
            'invoiceSearchDateFrom',
            payload.date && payload.date.from ? payload.date.from : ''
          );
          sessionStorage.setItem(
            'invoiceSearchDateTo',
            payload.date && payload.date.to ? payload.date.to : ''
          );
        } else {
          sessionStorage.setItem('invoiceSearchDateFrom', payload.date);
          sessionStorage.setItem(
            'invoiceSearchDateTo',
            moment().format('YYYY-MM-DD')
          );
        }

        // sessionStorage.setItem('invoiceSearchFilter', payload.filter);
        sessionStorage.setItem('invoiceSearchPaid', payload.paid);

        if (response && response.statusCode) {
          if (response.statusCode == 200) {
            patchState({
              bills: response.data,
            });
          }
        }
      })
    );
  }

  @Action(ClearBillingResponse)
  ClearCareteamResponse({ patchState }: StateContext<BillingStateModel>) {
    patchState({
      billingResponse: {
        responseMessage: undefined,
        statusCode: undefined,
      },
    });
  }

  @Action(FetchClinicCharges)
  fetchClinicCharges({ patchState }: StateContext<BillingStateModel>) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    return this.billingService.fetchClinicCharges(loginInfo.token).pipe(
      tap((response) => {
        if (response && response.statusCode) {
          if (response.statusCode == 200) {
            patchState({
              clinicCharges: response.data,
            });
          }
        }
      })
    );
  }

  @Action(SetClinicCharges)
  updateClinicCharges(
    { patchState }: StateContext<BillingStateModel>,
    { payload }: any
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );

    return this.billingService
      .updateClinicCharges(payload, loginInfo.token)
      .pipe(
        tap((response) => {
          if (response && response.statusCode) {
            if (response.statusCode == 200) {
              this.store.dispatch(new FetchClinicCharges());
            }
          }
        })
      );
  }

  @Action(FetchChargeLookup)
  fetchChargesLookup({ patchState }: StateContext<BillingStateModel>) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    return this.billingService.fetchChargeLookup(loginInfo.token).pipe(
      tap((response) => {
        if (response && response.statusCode) {
          if (response.statusCode == 200) {
            patchState({
              chargesLookUp: response.data,
            });
          }
        }
      })
    );
  }

  @Action(FetchPatientList)
  fetchPatients({ patchState }: StateContext<BillingStateModel>) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    return this.billingService.fetchPatientList(loginInfo.token).pipe(
      tap((response) => {
        if (response && response.statusCode) {
          if (response.statusCode == 200) {
            patchState({
              patients: response.data,
            });
          }
        }
      })
    );
  }

  @Action(AddPatientBill)
  addInvoice(
    { patchState }: StateContext<BillingStateModel>,
    { payload }: any
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    return this.billingService.addPatientnvoice(payload, loginInfo.token).pipe(
      tap((response) => {
        if (response && response.statusCode) {
          if (response.statusCode == 200) {
            this.alert.simpleAlert(
              'Invoice',
              'Invoice generated successfully.'
            );
            patchState({
              billingResponse: {
                statusCode: response.statusCode,
                responseMessage: 'Invoice Generated Successfully',
              },
            });
            this.store.dispatch(
              new FetchBillsByDate({
                date: moment().format('YYYY-MM-DD'),
                filter: false,
                staffId: loginInfo.staffId,
              })
            );
          } else {
            patchState({
              billingResponse: {
                statusCode: 0,
                responseMessage: 'Error',
              },
            });
          }
        }
      })
    );
  }
  @Action(DeletePatientBill)
  deleteInvoice(
    { patchState }: StateContext<BillingStateModel>,
    { payload }: any
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    return this.billingService
      .deletePatientInvoice(payload.data, loginInfo.token)
      .pipe(
        tap((response) => {
          if (response && response.statusCode) {
            if (response.statusCode == 200) {
              if(payload.refreshData){
                this.store.dispatch(
                  new FetchBillsByDate(payload.refreshData)
                );
              }
              else{
                this.store.dispatch(
                  new FetchBillsByDate({
                    date: payload.startDate
                      ? payload.startDate
                      : moment().format('YYYY-MM-DD'),
                    filter: false,
                    staffId: loginInfo.staffId,
                  })
                );
              }
            } else {
              patchState({
                billingResponse: {
                  statusCode: response.statusCode,
                  responseMessage: 'Error',
                },
              });
            }
          }
        })
      );
  }

  @Action(RefundPayment)
  RefundPaymentResponse(
    { patchState }: StateContext<BillingStateModel>,
    { payload }: any
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    if (loginInfo && loginInfo.token) {
      return this.billingService
        .refundPayment(loginInfo.token, payload)
        .pipe(
          tap((response: any) => {
            if (response && response.statusCode) {
              if (response.statusCode == 200) {
                if (payload?.refreshData) {
                  this.store.dispatch(
                    new FetchBillsByDate(payload?.refreshData)
                  ).subscribe(() => {
                    this.spinner.hide();
                    this.alert.simpleAlert('Refund', 'Payment Refunded Successfully');
                  });
                }
                else {
                  this.store.dispatch(
                    new FetchBillsByDate({
                      date: payload.startDate
                        ? payload.startDate
                        : moment().format('YYYY-MM-DD'),
                      filter: false,
                      staffId: loginInfo.staffId,
                    })
                  ).subscribe(() => {
                    this.spinner.hide();
                    this.alert.simpleAlert('Refund', 'Payment Refunded Successfully');
                  });
                }
              } else {
                patchState({
                  billingResponse: {
                    statusCode: response.statusCode,
                    responseMessage: response.message,
                  },
                });
                this.spinner.hide()
                this.alert.simpleAlert('Refund', response.message)
              }
            }
          })
        );
    }
  }

  @Action(FetchBillsByDateFromOscar)
  fetchInvoicefromOscar(
    { patchState }: StateContext<BillingStateModel>,
    { payload }: any
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    patchState({
      pdfFile: undefined,
    });
    return this.billingService
      .fetchBillsByDateFromOscar(payload, loginInfo.token)
      .pipe(
        tap((response) => {
          if (payload.date && payload.date.from) {
            sessionStorage.setItem(
              'reportSearchDateFrom',
              payload.date && payload.date.from ? payload.date.from : ''
            );
            sessionStorage.setItem(
              'reportSearchDateTo',
              payload.date && payload.date.to ? payload.date.to : ''
            );
          } else {
            sessionStorage.setItem('reportSearchDateFrom', payload.date);
            sessionStorage.setItem(
              'reportSearchDateTo',
              moment().format('YYYY-MM-DD')
            );
          }

          // sessionStorage.setItem('invoiceSearchFilter', payload.filter);
          sessionStorage.setItem('reportPaid', payload.paid);

          if (response && response.statusCode === 200) {
            patchState({ pdfFile: response?.data });
          } else {
            patchState({ pdfFile: response?.message });
          }
        })
      );
  }
}
