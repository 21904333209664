import {
  Component,
  EventEmitter,
  Input,
  Output,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import * as mapboxgl from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import { mapboxConfig } from '../../../core/environments/environment';
import { Select, Store } from '@ngxs/store';
import { ClearLocation, FetchLocation } from '../../../core/actions';
import { CommonState } from '../../../core/state/common.state';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
@Component({
  selector: 'quipo-mapbox',
  templateUrl: './mapbox.component.html',
  styleUrls: ['./mapbox.component.scss'],
})
export class mapboxComponent implements OnChanges {
  @Input() placeholder: string;
  @Input() required: boolean;
  @Input() submitted: boolean;
  @Input() label: string;
  @Input() isFullAddress: boolean;
  @Input() savedAddress: string;
  @Input() divId: string;
  @Input() saveCoordinates: boolean;
  // @Output() select = new EventEmitter();
  @Output() selectedAddress = new EventEmitter();
  validPhone: boolean;
  address: any;
  geocoder: any;
  map: any;
  isContactInfoCleared = false;
  isDataPresent = false;
  contactInfo: {
    address: string;
    city: string;
    province: string;
    postalcode: string;
    place_name: string;
    text: string;
    coordinates: string;
  };

  isManual = false;
  isIntial = true;

  private _destroy$: Subject<any>;

  @Select(CommonState.getLocations) locationList$: Observable<any>;
  mapLocations: any;
  locationList: any;

  constructor(private store: Store) {
    this.contactInfo = {
      address: '',
      city: '',
      province: '',
      postalcode: '',
      place_name: '',
      text: '',
      coordinates: '',
    };
    this.store.dispatch(new ClearLocation());

    this.locationList$.pipe(takeUntil(this.destroy$)).subscribe((locations) => {
      this.locationList = [];
      this.mapLocations = [];
      if (locations) {
        this.mapLocations = locations;
        locations.forEach((element) => {
          this.locationList.push(element.place_name);
        });
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    const change = changes['savedAddress'];
    if (change) {
      this.updateAddress();
    }
  }

  searchAddress($event) {
    if ($event && $event.preferedPharmacy)
      this.store.dispatch(new FetchLocation($event.preferedPharmacy));
  }

  getListItem(value) {
    const index = this.locationList.findIndex((item) => {
      return value == item;
    });
    if (index != -1) {
      this.contactInfo.address = '';
      this.contactInfo.postalcode = '';
      this.contactInfo.city = '';
      this.contactInfo.province = '';
      this.contactInfo.place_name = '';
      this.contactInfo.text = '';
      this.contactInfo.coordinates = '';

      setTimeout(() => {
        this.getAddressFromMap(this.mapLocations[index]);
      }, 10);
    } else {
      this.formatManualAddress(value);
    }
  }

  updateAddress() {
    if (this.geocoder) {
      setTimeout(() => {
        if (this.savedAddress) {
          let addressDet = this.savedAddress.split(',');
          if (this.savedAddress.includes(',coordinates')) {
            const addr = this.savedAddress.split(',coordinates:');
            addressDet = addr[0].split(',');
            const addrArrLength = addressDet.length;
            const address_name = addressDet[0];
            if (addrArrLength == 1) {
              this.contactInfo.address = address_name;
              this.contactInfo.place_name = this.savedAddress;
            } else {
              this.contactInfo.address = addressDet[0].trim();
              this.contactInfo.city = addressDet[1].trim();
              this.contactInfo.province = addressDet[2].trim();
              this.contactInfo.postalcode = addressDet[3].trim();
              this.contactInfo.place_name = this.savedAddress;
              this.contactInfo.text = addressDet[1].trim();
            }

            this.geocoder.setInput(this.contactInfo.address);
            this.isDataPresent = true;
          } else {
            const splitAddress = this.savedAddress.split(',');
            this.contactInfo.address = splitAddress[0].trim();
            this.contactInfo.city = splitAddress[1].trim();

            this.contactInfo.province = splitAddress[2];
            this.contactInfo.postalcode = splitAddress[3];
            this.contactInfo.place_name = this.savedAddress;
            this.contactInfo.text = addressDet[1];
          }
        } else {
          this.contactInfo = {
            address: '',
            city: '',
            province: '',
            postalcode: '',
            place_name: '',
            text: '',
            coordinates: '',
          };
          this.geocoder.setInput(this.contactInfo.address);
          this.isDataPresent = false;
        }
      }, 2);
    } else if (this.savedAddress) {
      const splitAddress = this.savedAddress?.split(',');
      if (splitAddress && splitAddress?.length > 0) {
        this.contactInfo.address = splitAddress?.[0];
        this.contactInfo.city = splitAddress?.[1]?.trim();
        this.contactInfo.province = splitAddress?.[2]?.trim();
        this.contactInfo.postalcode = splitAddress?.[3]?.trim();
        this.contactInfo.place_name = this.savedAddress;
        this.contactInfo.text = splitAddress?.[0];
      }
    }
  }
  getAddressFromMap(value?: any) {
    this.contactInfo.text = value.text;
    if (value.properties && value.properties.address)
      this.contactInfo.address = value.properties.address;
    else if (value.text) {
      this.contactInfo.address = value.address
        ? value.address + ' ' + value.text
        : value.text;
    }

    for (const index in value.context) {
      if (value.context[index].id.includes('postcode'))
        this.contactInfo.postalcode = value.context[index].text;
      else if (value.context[index].id.includes('place'))
        this.contactInfo.city = value.context[index].text;
      else if (value.context[index].id.includes('region')) {
        this.contactInfo.province = value.context[index].text;
      }
    }
    const cords = JSON.stringify(value.geometry.coordinates);
    this.contactInfo.coordinates = cords;
    this.contactInfo.place_name =
      this.contactInfo.address +
      ',' +
      this.contactInfo.city +
      ',' +
      this.contactInfo.province  +
      ',' +
      this.contactInfo.postalcode ;
      //  +
      // ',coordinates:' +
      // this.contactInfo.coordinates;

    this.submitted = true;
    this.selectedAddress.emit(this.contactInfo);
  }
  formatManualAddress(isAddress = true) {
    this.contactInfo.place_name =
      (this.contactInfo.address ? this.contactInfo.address : '' ) +
      ',' +
      (this.contactInfo.city ? this.contactInfo.city : '')  +
      ',' +
      (this.contactInfo.province ? this.contactInfo.province : '' ) +
      ',' +
      (this.contactInfo.postalcode ? this.contactInfo.postalcode : '' );

    if (isAddress) {
      this.contactInfo.coordinates = null;
    }
    // else if (this.contactInfo.coordinates) {
    //   this.contactInfo.place_name +=
    //     ',coordinates:' + this.contactInfo.coordinates;
    // }

    this.submitted = true;
    this.selectedAddress.emit(this.contactInfo);
  }
  // formattedadrs(value?: any) {
  //   console.log('Value', value);
  //   if (value == '') {
  //     this.contactInfo.address = '';
  //     this.contactInfo.postalcode = '';
  //     this.contactInfo.city = '';
  //     this.contactInfo.province = '';
  //     this.contactInfo.place_name = '';
  //     this.contactInfo.text = '';
  //     this.contactInfo.coordinates = '';
  //   }
  //   if (value && value.text) {
  //     this.contactInfo.text = value.text;
  //     if (value.properties && value.properties.address)
  //       this.contactInfo.address = value.properties.address;
  //     else this.contactInfo.address = value.place_name.split(',')[0];
  //     for (const index in value.context) {
  //       if (value.context[index].id.includes('postcode'))
  //         this.contactInfo.postalcode = value.context[index].text;
  //       else if (value.context[index].id.includes('place'))
  //         this.contactInfo.city = value.context[index].text;
  //       else if (value.context[index].id.includes('region')) {
  //         this.contactInfo.province = value.context[index].text;
  //       }
  //     }
  //     const cords = JSON.stringify(value.geometry.coordinates);
  //     this.contactInfo.coordinates = cords;
  //     this.contactInfo.place_name =
  //       this.contactInfo.address +
  //       ',' +
  //       this.contactInfo.city +
  //       ',' +
  //       this.contactInfo.province +
  //       ',' +
  //       this.contactInfo.postalcode +
  //       ',coordinates:' +
  //       this.contactInfo.coordinates;
  //   } else if (this.isManual) {
  //     this.contactInfo.place_name =
  //       this.contactInfo.address +
  //       ',' +
  //       this.contactInfo.city +
  //       ',' +
  //       this.contactInfo.province +
  //       ',' +
  //       this.contactInfo.postalcode;
  //   } else {
  //     let splittedAddress;
  //     if (this.contactInfo.place_name.includes('coordinates')) {
  //       splittedAddress = this.contactInfo.place_name.split(',coordinates:');
  //     }
  //     this.contactInfo.place_name =
  //       this.contactInfo.address +
  //       ',' +
  //       this.contactInfo.city +
  //       ',' +
  //       this.contactInfo.province +
  //       ',' +
  //       this.contactInfo.postalcode;
  //     if (splittedAddress && splittedAddress[1])
  //       this.contactInfo.place_name =
  //         this.contactInfo.place_name + ',coordinates:' + splittedAddress[1];
  //   }
  //   this.submitted = true;
  //   this.selectedAddress.emit(this.contactInfo);
  // }

  getAddress() {
    return this.contactInfo;
  }

  changeHandler(event) {
    this.contactInfo.postalcode = event.target.value.toUpperCase();
    const isSpaced = /\s/.test(this.contactInfo.postalcode.trim());
    if (!isSpaced) {
      const first = this.contactInfo.postalcode.slice(0, 3);
      const second = this.contactInfo.postalcode.slice(3);
      this.contactInfo.postalcode = first + ' ' + second;
    }
  }

  isZipCodeValid(data) {
    const pattern = /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/;
    return pattern.test(data);
  }

  get destroy$() {
    if (!this._destroy$) {
      this._destroy$ = new Subject();
    }
    return this._destroy$;
  }
  ngOnDestroy() {
    if (this._destroy$) {
      this._destroy$.next(true);
      this._destroy$.complete();
    }
  }
}
