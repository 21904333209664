<span class="add-care-image" quipoTooltip="Add Image" [tooltipPosition]="'bottom'" (change)="uploadImage($event)">
  <label for="file-input">
    <img class="user-image" [src]="imageURL ? imageURL : defaultImage" alt="" />
  </label>
  <input
    id="file-input"
    type="file"
    accept=".png, .jpeg"
    (click)="$event.target.value = null"
  />
</span>
<div *ngIf="showSizeErr" class="form-validation-errors">
  <strong> Please upload an image of size less than 1Mb. </strong>
</div>
<div *ngIf="showTypeErr" class="form-validation-errors">
  <strong> Please upload an image file. </strong>
</div>
