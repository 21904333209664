export class FetchAllReasons {
  static readonly type = '[Appointment] Fetch All';
}
export class FetchAllpatientTypes {
  static readonly type = '[PatientType] Fetch All';
}

export class FetchAppointmentQuestionnaire {
  static readonly type = '[AppointmentQuestionnaire] Fetch All';
  constructor(public payload) { }
}
export class SetSelectedReason {
  static readonly type = '[Appointment] Set Reason';
  constructor(public payload: any) { }
}

export class BookAppointment {
  static readonly type = '[Appointment] Book Appointment';
  constructor(public payload) { }
}
export class ClearAppointmentResponse {
  static readonly type = '[Appointment] Clear Appointment Response';
  constructor() { }
}

export class ClearAppointmentQuestionnaire {
  static readonly type = '[Appointment] Clear Appointment questionnaire';
  constructor() { }
}

export class FetchAppointment {
  static readonly type = '[Appointment] Fetch';
  constructor(public payload) { }
}
export class FetchAppointmentByOscarId {
  static readonly type = '[AppointmentByOscarId] Fetch';
  constructor(public payload) { }
}

export class ConfirmAppointment {
  static readonly type = '[Appointment] Confirm';
  constructor(public payload) { }
}
export class SetSelectedAppointment {
  static readonly type = '[Appointment] Set Appointment';
  constructor(public payload: any) { }
}

export class UpdateSelectedAppointment {
  static readonly type = '[Appointment] Update';
  constructor(public payload: any) { }
}

export class FetchAllToDoList {
  static readonly type = '[ToDo] Fetch All';
  constructor(public payload) { }
}

export class ClearToDoResponse {
  static readonly type = '[ToDo] Clear Response';
  constructor() { }
}

export class FetchGeneralPractitioner {
  static readonly type = '[Appointment] fetch General Practitioner';
  constructor(public payload) { }
}

export class FetchAvailableAppointments {
  static readonly type = '[Appointment] fetch available appointments';
  constructor() { }
}

export class FetchAvailableAppointmentsByProvider {
  static readonly type =
    '[Appointment] fetch available appointment by provider';
  constructor(public payload) { }
}

export class SetSelectedPhysician {
  static readonly type = '[Appointment] Set Selected Physician';
  constructor(public payload) { }
}

export class ClearAppointmentSelection {
  static readonly type = '[Appointment] Set Seleted appointment list';
  constructor() { }
}
export class ClearAvailableAppointments {
  static readonly type = '[Appointment] clear Available Appointments list';
  constructor() { }
}

export class FetchFirstAvailableAppointment {
  static readonly type = '[Appointment] fetch first available appointment';
  constructor(public payload) { }
}

export class ClearAppointmentState {
  static readonly type = '[Appointment] State';
}

export class ClearAppointmentsList {
  static readonly type = '[Appointments] Clear';
}
export class ClearFirstAvailable {
  static readonly type = '[First Available] Clear';
}

export class FetchAvailableDatesByProvider {
  static readonly type = '[Appointment] fetch available dates by provider';
  constructor(public payload) { }
}
export class ClearAvailableDatesList {
  static readonly type = '[AppointmentDates] Clear';
}

export class AppointmentValidation {
  static readonly type = '[Appointment] Validation';
  constructor(public id, public payload) { }
}

export class FetchCommunityFilters {
  static readonly type = '[Community Filters] Fetch';
}

export class SearchCommunityPhysician {
  static readonly type = '[Appointment] Community Search';
  constructor(public payload) { }
}

export class ClearCommunityPhysicians {
  static readonly type = '[Community List] Clear';
}
export class SetPatientCovidQuestionnaire {
  static readonly type = '[CovidQuestionnaire] Set';
  constructor(public payload: any) { }
}
export class ClearpatientCovidQuestionnaire {
  static readonly type =
    '[CovidQuestionnaire] Clear CovidQuestionnaire questionnaire';
  constructor() { }
}
export class ClearCheckInDetails {
  static readonly type = '[ClearCheckInDetails] Clear check in details';
  constructor() { }
}
export class SetcheckInDetails {
  static readonly type = '[CheckInDetails] Set';
  constructor(public payload: any) { }
}

export class GetReviewLinks {
  static readonly type = '[Review Links] Fetch';
  constructor(public payload: any) { }
}

export class AddReview {
  static readonly type = '[Review] Add';
  constructor(public payload: any) { }
}

export class FetchPhysicianMessage {
  static readonly type = '[Message] Fetch';
  constructor(public payload: any) { }
}
