import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
@Component({
  selector: 'quipo-email-input',
  templateUrl: './email-input.component.html',
  styleUrls: ['./email-input.component.scss'],
})
export class EmailInputComponent {
  @Input() label: string;
  @Input() type: string;
  @Input() isRequired: boolean;
  @Input() icon;
  inputContent: string;
  @Input() disabled: boolean;
  @Input() submitted: boolean;
  @Input() placeholder: string;
  @Input() value: string;
  @Input() width: string;
  @Output() valueChange = new EventEmitter();

  // emailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  errorText = null;

  constructor() {
    this.errorText = null;
    this.submitted = false;
    this.isRequired = false;
    this.disabled = false;
  }

  keyPress(event){
    if(event.keyCode == 32){
      return false;
    }
    return true;
  }

  change(newValue: string) {
    newValue = newValue.toLowerCase();
    this.valueChange.emit(newValue);
  }
  validate(element) {
    const data = element.value;
    if (this.emailPattern.test(data)) {
      this.errorText = null;
    } else {
      this.errorText = 'email-invalid';
    }
  }

  getWidth() {
    if (this.width) {
      return this.width;
    }
  }
}
