import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}
platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then(() => {
    // if ('serviceWorker' in navigator && environment.production) {
    //    navigator.serviceWorker.register('/ngsw-worker.js');
    // }
    // [Info] since we are connecting v1 and v2 of the app, we need to clear the cache. else it wont redirect to the new version
  })
  .catch((err) => console.error(err));
