import * as moment from 'moment';
const imagebaseurl = '';

// eslint-disable-next-line prefer-const
//aws
// eslint-disable-next-line prefer-const
//let baseurl = 'https://dev.quipohealth.com';
//medstack
// eslint-disable-next-line prefer-const
// let baseurl = process.env.baseurl || 'https://api-prod.quipohealth.com';
// const data = {
//   baseurl: baseurl,
//   production: true,
//   imagebaseurl: imagebaseurl,
//   authUrl: `${baseurl}/authentication-api`,
//   registrationUrl: `${baseurl}/registration-api`,
//   appointmentUrl: `${baseurl}/appointment-api`,
//   serviceIntegrationUrl: `${baseurl}/service-integrations-api`,
//   questionnaireUrl: `${baseurl}/questionnaire-api`,
//   adminUrl: `${baseurl}/admin-api`,
//   consultationUrl: `${baseurl}/consultation-api`,
//   oscarDoctorId: baseurl === 'https://live.quipohealth.com' ? 2 : 3,
// };
let baseurl = process.env.baseurl || 'https://api-dev.quipohealth.com';
const data = {
  baseurl: baseurl,
  production: true,
  imagebaseurl: imagebaseurl,
  authUrl: `${baseurl}/authentication/authentication-api`,
  registrationUrl: `${baseurl}/registration/registration-api`,
  appointmentUrl: `${baseurl}/appointment/appointment-api`,
  serviceIntegrationUrl: `${baseurl}/integration/service-integrations-api`,
  questionnaireUrl: `${baseurl}/questionnaire/questionnaire-api`,
  adminUrl: `${baseurl}/admin/admin-api`,
  consultationUrl: `${baseurl}/consultation/consultation-api`,
  oscarDoctorId: baseurl === 'https://dev.quipohealth.com' ? 2 : 3,
};

//To connect to local server
//data.authUrl = `http://localhost:3334/authentication-api`;
// data.registrationUrl = `http://localhost:3333/registration-api`;
//data.appointmentUrl = `http://localhost:3335/appointment-api`;
// data.serviceIntegrationUrl = `http://localhost:3337/service-integrations-api`;
// data.questionnaireUrl = `http://localhost:3336/questionnaire-api`;
// data.adminUrl = `http://localhost:3338/admin-api`;
// data.consultationUrl = `http://localhost:3340/consultation-api`;
export const environment = data;
export const calendarConfig = {
  format: 'yyyy-mm-dd',
  placeholder: 'YYYY-MM-DD',
  // max: moment(), //Set maximum valid date
  max: {
    year: moment().year(),
    month: moment().month() + 1, //Current month
    day: moment().date() + 1, //next day
  },
};
export const mapboxConfig = {
  accessToken:
    'pk.eyJ1IjoicXVpcG9oZWFsdGgiLCJhIjoiY2ttdW5wa3R0MGt4MjJvbzJjMThqMDFjeCJ9.ULd5LCGc2vl35l7utBFlug',
};
//local- sonia's account
// export const twilio = {
//   phoneno: '+1 330 441 4399',
//   appsid: 'AC23cd729eb52492a807abd4c3231ee885',
//   accesskey: '336fb2e44333a22736d7350172bbe14b',
// };
// local - navaneeth's account
// export const twilio = {
//   phoneno: '+12193793504',
//   appsid: 'AC128104bb8c8d020a52f694f7f3eae3c',
//   accesskey: '7913e1a3d727c6d32593a2b06d155f77',
// };

//local
export const supportAdminID = 'cfe5b070-7dc8-4393-91c3-53d2b84983fd';
export const twilio = {
  phoneno: '+17782008796',
  appsid: 'ACeb3c21cc52318810b907a2333541bb73',
  accesskey: 'c7755530b73d4de83e5ad067df729318',
};
