import { Injectable } from '@angular/core';
import { Action, State, StateContext, Selector, Store } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { AppointmentService } from '../services/appointment.service';
import { PatientAppointment } from '../interfaces/patient-appointment.interface';
import {
  BookAppointment,
  FetchAllReasons,
  FetchAllpatientTypes,
  FetchAppointmentQuestionnaire,
  ClearAppointmentResponse,
  ClearAppointmentQuestionnaire,
  ClearpatientCovidQuestionnaire,
  ClearCheckInDetails,
  SetSelectedReason,
  FetchAppointment,
  FetchAppointmentByOscarId,
  ConfirmAppointment,
  SetSelectedAppointment,
  SetPatientCovidQuestionnaire,
  SetcheckInDetails,
  UpdateSelectedAppointment,
  FetchAllToDoList,
  FetchGeneralPractitioner,
  FetchAvailableAppointments,
  FetchFirstAvailableAppointment,
  FetchAvailableAppointmentsByProvider,
  FetchAvailableDatesByProvider,
  SetSelectedPhysician,
  ClearToDoResponse,
  ClearAppointmentSelection,
  ClearAvailableAppointments,
  ClearAppointmentState,
  ClearAppointmentsList,
  ClearAvailableDatesList,
  AppointmentValidation,
  FetchCommunityFilters,
  SearchCommunityPhysician,
  ClearCommunityPhysicians,
  GetReviewLinks,
  AddReview,
  FetchPhysicianMessage,
  ClearFirstAvailable,
} from '../actions/appointment.action';
import { ListData } from '../interfaces/common.interface';
import { AppointmentResponse } from '../interfaces/patient-appointment.interface';
import { UserAuthState } from './user-auth.state';
import { FamilyMemberState } from './family-member.state';
import { Router } from '@angular/router';

export class AppointmentStateModel {
  patientAppointment: PatientAppointment;
  appointmentResponse: AppointmentResponse;
  selectedReason: ListData;
  appointments: any;
  availaleDateList: any;
  selectedAppointment: any;
  patientCovidQuestionnaire: any;
  checkInDetails: any;
  toDoList: any;
  generalPractitioner: any;
  availableAppointments: any;
  availableAppointmentsByProvider: any;
  firstAvailableAppointment: any;
  selectedPhysician: any;
  appointmentBooked: any;
  appointmentQuestionnaire: any;
  patientList: any;
  communityFiltersList: any;
  communityPhysicians: any;
  reviewLinks: any;
  customMessage: string;
}

export class ReasonStateModel {
  reasonData: ListData[];
  patientTypes: ListData[];
}

@State<AppointmentStateModel>({
  name: 'appointmentDetails',
  defaults: {
    patientAppointment: {
      appointmentId: undefined,
      patientId: undefined,
      reasonId: undefined,
      reason: undefined,
      attachmentPath: undefined,
      appointmentType: undefined,
      preferedPharmacy: undefined,
      patientPhoneNumber: undefined,
      patientEmail: undefined,
      appointmentDate: undefined,
      appointmetTime: undefined,
      appointmentStatus: undefined,
      questionnaireId: undefined,
      questionnaire: undefined,
      starRating: undefined,
      doctorId: undefined,
    },
    appointmentQuestionnaire: undefined,
    firstAvailableAppointment: undefined,
    generalPractitioner: undefined,
    availableAppointments: undefined,
    availableAppointmentsByProvider: undefined,
    selectedPhysician: undefined,
    appointmentResponse: {
      appointmentId: undefined,
      errorMessage: undefined,
      statusCode: 0,
    },
    selectedReason: undefined,
    appointments: undefined,
    selectedAppointment: undefined,
    patientCovidQuestionnaire: undefined,
    checkInDetails: undefined,
    toDoList: {},
    availaleDateList: undefined,
    appointmentBooked: undefined,
    patientList: undefined,
    communityFiltersList: undefined,
    communityPhysicians: undefined,
    reviewLinks: undefined,
    customMessage: undefined,
  },
})
@Injectable()
export class AppointmentState {
  constructor(
    private appointmentService: AppointmentService,
    public store: Store,
    public router: Router
  ) { }

  @Selector()
  static bookAppointment(state: AppointmentStateModel) {
    return state.patientAppointment;
  }
  @Selector()
  static getAppointmentQuestionnaire(state: AppointmentStateModel) {
    return state.appointmentQuestionnaire;
  }

  @Selector()
  static getAppointmentResponse(state: AppointmentStateModel) {
    return state.appointmentResponse;
  }
  @Selector()
  static getReasons(state: ReasonStateModel) {
    return state.reasonData;
  }
  @Selector()
  static getPatientTypes(state: ReasonStateModel) {
    return state.patientTypes;
  }

  @Selector()
  static getSelectedReason(state: AppointmentStateModel) {
    return state.selectedReason;
  }
  @Selector()
  static getselectedPhysician(state: AppointmentStateModel) {
    return state.selectedPhysician;
  }

  @Selector()
  static getAppointments(state: AppointmentStateModel) {
    return state.appointments;
  }
  @Selector()
  static getGeneralPractitioner(state: AppointmentStateModel) {
    return state.generalPractitioner;
  }
  @Selector()
  static getAvailableAppointments(state: AppointmentStateModel) {
    return state.availableAppointments;
  }
  @Selector()
  static getfirstAvailableAppointment(state: AppointmentStateModel) {
    return state.firstAvailableAppointment;
  }

  @Selector()
  static getAvailableAppointmentsByProviders(state: AppointmentStateModel) {
    return state.availableAppointmentsByProvider;
  }
  @Selector()
  static getSelectedAppointment(state: AppointmentStateModel) {
    return state.selectedAppointment;
  }

  @Selector()
  static getpatientCovidQuestionnaire(state: AppointmentStateModel) {
    return state.patientCovidQuestionnaire;
  }
  @Selector()
  static getcheckInDetails(state: AppointmentStateModel) {
    return state.checkInDetails;
  }
  // Fetching To-Do list data
  @Selector()
  static getToDoListDetails(state: AppointmentStateModel) {
    return state.toDoList;
  }

  @Selector()
  static getAvailableDates(state: AppointmentStateModel) {
    return state.availaleDateList;
  }
  @Selector()
  static getBookedAppointment(state: AppointmentStateModel) {
    return state.appointmentBooked;
  }
  @Selector()
  static getCommunityFiltersList(state: AppointmentStateModel) {
    return state.communityFiltersList;
  }
  @Selector()
  static getCommunityPhysiciansList(state: AppointmentStateModel) {
    return state.communityPhysicians;
  }

  @Selector()
  static getReviewLinks(state: AppointmentStateModel) {
    return state.reviewLinks;
  }

  @Selector()
  static getCustomMessage(state: AppointmentStateModel) {
    return state.customMessage;
  }

  @Action(ClearAppointmentResponse)
  ClearAppointmentResponse({
    patchState,
  }: StateContext<AppointmentStateModel>) {
    patchState({
      appointmentResponse: {
        appointmentId: undefined,
        statusCode: 0,
        errorMessage: undefined,
      },
    });
  }
  @Action(ClearAppointmentQuestionnaire)
  ClearAppointmentQuestionnaire({
    patchState,
  }: StateContext<AppointmentStateModel>) {
    patchState({
      appointmentQuestionnaire: undefined,
    });
  }

  @Action(ClearpatientCovidQuestionnaire)
  ClearpatientCovidQuestionnaire({
    patchState,
  }: StateContext<AppointmentStateModel>) {
    patchState({
      patientCovidQuestionnaire: undefined,
    });
  }
  @Action(ClearCheckInDetails)
  ClearCheckInDetails({ patchState }: StateContext<AppointmentStateModel>) {
    patchState({
      checkInDetails: undefined,
    });
  }

  @Action(ClearToDoResponse)
  ClearToDoResponse({ patchState }: StateContext<AppointmentStateModel>) {
    patchState({
      toDoList: {},
    });
  }

  @Action(BookAppointment)
  BookAppointment(
    { patchState }: StateContext<AppointmentStateModel>,
    { payload }: any
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    return this.appointmentService
      .bookAppointment(payload, loginInfo.token)
      .pipe(
        tap((response: any) => {
          if (response && response.statusCode) {
            if (response.statusCode == 200) {
              if (response.data && response.data.appointmentId) {
                patchState({
                  appointmentResponse: {
                    appointmentId: response.data.appointmentId,
                    statusCode: 200,
                    errorMessage: undefined,
                  },
                });
              }
            } else {
              patchState({
                appointmentResponse: {
                  appointmentId: undefined,
                  statusCode: response.statusCode,
                  errorMessage: response.message ? response.message : 'Error',
                },
              });
            }
          } else {
            patchState({
              appointmentResponse: {
                appointmentId: undefined,
                statusCode: 0,
                errorMessage: undefined,
              },
            });
          }
        })
      );
  }

  @Action(FetchAllpatientTypes)
  FetchAllpatientTypes({ patchState }: StateContext<any>) {
    return this.appointmentService.fetchReasons().pipe(
      tap((response: any) => {
        if (response && response.statusCode) {
          if (response.statusCode == 200) {
            if (response.data) {
              patchState({
                patientTypes: response.data,
              });
              return;
            }
          }
          patchState({
            patientTypes: [],
          });
        }
      })
    );
  }

  @Action(FetchAllReasons)
  FetchAllAppointmentReasons({ patchState }: StateContext<any>) {
    return this.appointmentService.fetchReasons().pipe(
      tap((response: any) => {
        if (response && response.statusCode) {
          if (response.statusCode == 200) {
            if (response.data) {
              patchState({
                reasonData: response.data,
              });
              return;
            }
          }
          patchState({
            reasonData: [],
          });
        }
      })
    );
  }

  @Action(FetchAppointmentQuestionnaire)
  FetchAppointmentQuestionnaire(
    { patchState }: StateContext<AppointmentStateModel>,
    { payload }: any
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    return this.appointmentService
      .fetchAppointmentQuestionnaire(payload, loginInfo.token)
      .pipe(
        tap((response: any) => {
          if (response && response.statusCode) {
            if (response.statusCode == 200) {
              if (response.data) {
                patchState({
                  appointmentQuestionnaire: response.data,
                });
                return;
              }
            }
            patchState({
              appointmentQuestionnaire: undefined,
            });
          }
        })
      );
  }

  @Action(SetSelectedReason)
  setSelectedReason(
    { patchState }: StateContext<AppointmentStateModel>,
    { payload }: any
  ) {
    // const reasonList = this.store.selectSnapshot(AppointmentState.getReasons);
    // reasonList.forEach((reason) => {
    //   if (reason.itemCode == payload) {
    patchState({
      selectedReason: payload,
    });
    //   }
    // });
  }

  @Action(FetchAppointment)
  fetchAppointment(
    { patchState }: StateContext<AppointmentStateModel>,
    { payload }: any
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    return this.appointmentService
      .fetchAppointment(payload, loginInfo.token)
      .pipe(
        tap((response) => {
          if (response && response.statusCode) {
            if (response.statusCode == 200) {
              patchState({
                appointments: response.data,
              });
              return;
            }
            patchState({
              appointments: [],
            });
          }
        })
      );
  }
  @Action(FetchAppointmentByOscarId)
  fetchAppointmentByOscarId(
    { patchState }: StateContext<AppointmentStateModel>,
    { payload }: any
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    return this.appointmentService
      .fetchAppointmentByOscarId(payload, loginInfo.token)
      .pipe(
        tap((response) => {
          if (response && response.statusCode) {
            if (response.statusCode == 200) {
              patchState({
                appointments: response.data,
              });
              return;
            }
            patchState({
              appointments: [],
            });
          }
        })
      );
  }

  @Action(ClearAppointmentsList)
  ClearAppointmentList({ patchState }: StateContext<AppointmentStateModel>) {
    patchState({
      appointments: undefined,
    });
  }

  @Action(ConfirmAppointment)
  confirmAppointment(
    { patchState }: StateContext<AppointmentStateModel>,
    { payload }: any
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    const selectedPatient = this.store.selectSnapshot(
      FamilyMemberState.getSelectedPatient
    );
    return this.appointmentService
      .confirmAppointment(payload, loginInfo.token)
      .pipe(
        tap((response) => {
          if (response && response.statusCode) {
            if (response.statusCode == 200) {
              this.store.dispatch(new FetchAppointment(selectedPatient.id));
            }
          }
        })
      );
  }

  @Action(SetSelectedAppointment)
  setSelectedAppointment(
    { patchState }: StateContext<AppointmentStateModel>,
    { payload }: any
  ) {
    if (payload) {
      patchState({
        selectedAppointment: payload,
      });
    }
  }

  @Action(UpdateSelectedAppointment)
  updateAppointment(
    { patchState }: StateContext<AppointmentStateModel>,
    { payload }: any
  ) {
    const appointment = this.store.selectSnapshot(
      AppointmentState.getSelectedAppointment
    );
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );

    let appointmentId = undefined;
    if (appointment && appointment.appointmentId)
      appointmentId = appointment.appointmentId;
    if (!appointmentId && payload.appointmentReason) appointmentId = payload.id;
    return this.appointmentService
      .updateAppointment(appointmentId, payload, loginInfo.token)
      .pipe(
        tap((response) => {
          if (response && response.statusCode) {
            patchState({
              appointmentResponse: response,
            });
          }
        })
      );
  }

  @Action(FetchAllToDoList)
  FetchAllToDoList({ patchState }: StateContext<any>, { payload }: any) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    return this.appointmentService.fetchToDo(payload, loginInfo.token).pipe(
      tap((response: any) => {
        if (response && response.statusCode) {
          if (response.statusCode == 200) {
            if (response.data) {
              patchState({
                toDoList: response.data,
              });
              return;
            }
          }
          patchState({
            toDoList: {},
          });
        }
      })
    );
  }

  @Action(FetchGeneralPractitioner)
  fetchGeneralPractitioner(
    { patchState }: StateContext<AppointmentStateModel>,
    { payload }: any // patientId
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    return this.appointmentService
      .fetchGeneralPractitioner(payload, loginInfo.token)
      .pipe(
        tap((response) => {
          if (response && response.statusCode) {
            if (response.statusCode == 200) {
              patchState({
                generalPractitioner: response.data,
              });
            } else {
              patchState({
                generalPractitioner: {},
              });
            }
          }
        })
      );
  }

  @Action(FetchFirstAvailableAppointment)
  FetchFirstAvailableAppointment(
    { patchState }: StateContext<AppointmentStateModel>,
    { payload }: any //OscarDoctorId
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    return this.appointmentService
      .FetchFirstAvailableAppointment(loginInfo.token, payload)
      .pipe(
        tap((response) => {
          if (response && response.statusCode) {
            if (response.statusCode == 200 && response.data) {
              patchState({
                firstAvailableAppointment: response.data,
              });
            } else {
              patchState({
                firstAvailableAppointment: [],
              });
            }
          } else {
            patchState({
              firstAvailableAppointment: { statusCode: response.statusCode },
            });
          }
        })
      );
  }

  @Action(FetchAvailableAppointments)
  fetchAvailableAppointments(
    { patchState }: StateContext<AppointmentStateModel>,
    { payload }: any //OscarDoctorId
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );

    const selectedPhysician = this.store.selectSnapshot(
      AppointmentState.getselectedPhysician
    );

    return this.appointmentService
      .fetchAvailableAppointments(loginInfo.token, selectedPhysician)
      .pipe(
        tap((response) => {
          if (response && response.statusCode) {
            if (response.statusCode == 200) {
              patchState({
                availableAppointments: { appointments: response.data },
              });
            } else {
              patchState({
                availableAppointments: undefined,
              });
            }
          } else {
            patchState({
              availableAppointments: undefined,
            });
          }
        })
      );
  }

  @Action(FetchAvailableAppointmentsByProvider)
  fetchAvailableAppointmentsByProvider(
    { patchState }: StateContext<AppointmentStateModel>,
    { payload }: any
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    if (!payload.type) return;
    return this.appointmentService
      .fetchAvailableAppointmentsByProvider(payload, loginInfo.token)
      .pipe(
        tap((response) => {
          if (response && response.statusCode) {
            if (response.statusCode == 200) {
              if (response.data) {
                patchState({
                  availableAppointmentsByProvider: {
                    appointments: response.data,
                  },
                });
              } else {
                patchState({
                  availableAppointmentsByProvider: { appointments: [] },
                });
              }
            } else {
              patchState({
                availableAppointmentsByProvider: { appointments: [] },
              });
            }
          } else {
            patchState({
              availableAppointmentsByProvider: { appointments: [] },
            });
          }
        })
      );
  }

  @Action(SetSelectedPhysician)
  SetSelectedPhysician(
    { patchState }: StateContext<AppointmentStateModel>,
    { payload }: any
  ) {
    patchState({
      selectedPhysician: payload,
    });
  }

  @Action(ClearAppointmentSelection)
  ClearAppointmentSelection({
    patchState,
  }: StateContext<AppointmentStateModel>) {
    patchState({
      selectedPhysician: undefined,
      firstAvailableAppointment: undefined,
      generalPractitioner: undefined,
      availableAppointments: undefined,
      availableAppointmentsByProvider: undefined,
      appointmentBooked: undefined,
    });
  }

  @Action(ClearAvailableAppointments)
  ClearAvailableAppointments({
    patchState,
  }: StateContext<AppointmentStateModel>) {
    patchState({
      availableAppointmentsByProvider: undefined,
      appointmentBooked: undefined,
    });
  }

  @Action(ClearAppointmentState)
  clearAppointmentState({ patchState }: StateContext<AppointmentStateModel>) {
    patchState({
      patientAppointment: undefined,
      firstAvailableAppointment: undefined,
      generalPractitioner: undefined,
      availableAppointments: undefined,
      availableAppointmentsByProvider: undefined,
      selectedPhysician: undefined,
      appointmentResponse: undefined,
      selectedReason: undefined,
      appointments: undefined,
      selectedAppointment: undefined,
      patientCovidQuestionnaire: undefined,
      checkInDetails: undefined,
      toDoList: undefined,
    });
  }

  @Action(FetchAvailableDatesByProvider)
  fetchAvailableDatesByProvider(
    { patchState }: StateContext<AppointmentStateModel>,
    { payload }: any
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    if (!payload.type) return;
    return this.appointmentService
      .fetchAvailableDatesByProvider(payload, loginInfo.token)
      .pipe(
        tap((response) => {
          if (response && response.statusCode) {
            //   if (response.statusCode == 200) {
            //     if (!response.data) {
            //       patchState({
            //         availaleDateList: [],
            //       });
            //     } else {
            //       patchState({
            //         availaleDateList: response.data,
            //       });
            //     }
            //   } else {
            //     patchState({
            //       availaleDateList: [],
            //     });
            //   }
            // } else {
            //   patchState({
            //     availaleDateList: [],
            //   });
            patchState({
              availaleDateList: response,
            });
          }
        })
      );
  }
  @Action(ClearAvailableDatesList)
  ClearAvailableDatesList({ patchState }: StateContext<AppointmentStateModel>) {
    patchState({
      availaleDateList: undefined,
    });
  }
  @Action(ClearFirstAvailable)
  ClearFirstAvailable({ patchState }: StateContext<AppointmentStateModel>) {
    patchState({
      firstAvailableAppointment: undefined,
    });
  }

  @Action(AppointmentValidation)
  checkAppointment(
    { patchState }: StateContext<AppointmentStateModel>,
    { payload, id }: any
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    return this.appointmentService
      .validateAppointment(id, payload, loginInfo.token)
      .pipe(
        tap((response) => {
          if (response && response.statusCode) {
            if (response.statusCode == 200) {
              patchState({
                appointmentBooked: response.data,
              });
            } else {
              patchState({
                appointmentBooked: undefined,
              });
            }
          }
        })
      );
  }

  @Action(FetchCommunityFilters)
  fetchCommunityFilters({ patchState }: StateContext<AppointmentStateModel>) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    return this.appointmentService.fetchCommunityFilters(loginInfo.token).pipe(
      tap((response) => {
        if (response && response.statusCode) {
          if (response.statusCode == 200) {
            patchState({
              communityFiltersList: response.data,
            });
          }
        } else {
          patchState({
            communityFiltersList: undefined,
          });
        }
      })
    );
  }

  @Action(SearchCommunityPhysician)
  searchPhysician(
    { patchState }: StateContext<AppointmentStateModel>,
    { payload }: any
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    return this.appointmentService
      .searchCommunityPhysician(payload, loginInfo.token)
      .pipe(
        tap((response) => {
          if (response && response.statusCode) {
            if (response.statusCode) {
              patchState({
                communityPhysicians: response.data ? response.data : [],
              });
            } else {
              patchState({
                communityPhysicians: [],
              });
            }
          } else {
            patchState({
              communityPhysicians: [],
            });
          }
        })
      );
  }

  @Action(ClearCommunityPhysicians)
  clearCommunityPhysicians({
    patchState,
  }: StateContext<AppointmentStateModel>) {
    patchState({
      communityPhysicians: undefined,
    });
  }

  @Action(SetPatientCovidQuestionnaire)
  SetPatientCovidQuestionnaire(
    { patchState }: StateContext<AppointmentStateModel>,
    { payload }: any
  ) {
    if (payload) {
      patchState({
        patientCovidQuestionnaire: payload,
      });
    }
  }

  @Action(SetcheckInDetails)
  SetcheckInDetails(
    { patchState }: StateContext<AppointmentStateModel>,
    { payload }: any
  ) {
    if (payload) {
      patchState({
        checkInDetails: payload,
      });
    }
  }

  @Action(GetReviewLinks)
  getReviewLinks(
    { patchState }: StateContext<AppointmentStateModel>,
    { payload }: any
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );

    return this.appointmentService
      .getReviewLinks(payload, loginInfo.token)
      .pipe(
        tap((response) => {
          if (response && response.statusCode) {
            if (response.statusCode == 200) {
              patchState({
                reviewLinks: response.data,
              });
            }
          } else {
            patchState({
              reviewLinks: undefined,
            });
          }
        })
      );
  }

  @Action(AddReview)
  addReview(
    { patchState }: StateContext<AppointmentStateModel>,
    { payload }: any
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );

    return this.appointmentService.addReview(payload, loginInfo.token).pipe(
      tap((response) => {
        if (response && response.statusCode) {
          if (response.statusCode == 200) {
           // console.log('Response', response.data);
          }
        }
      })
    );
  }

  @Action(FetchPhysicianMessage)
  fetchCustomMessage(
    { patchState }: StateContext<AppointmentStateModel>,
    { payload }: any
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    return this.appointmentService
      .getCustomMessage(payload, loginInfo.token)
      .pipe(
        tap((response) => {
          if (response && response.statusCode) {
            if (response.statusCode == 200) {
              patchState({
                customMessage: response.data.message,
              });
            }
          }
        })
      );
  }
}
