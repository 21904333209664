import { Injectable } from '@angular/core';
import { Action, Selector, State, Store, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import {
  InitOscarIntegration,
  authorizeOscarIntegration,
  clearOscarAuthorizeFlag,
  FetchData,
  FetchDoctorsList,
  FetchPharmacyData,
  FetchAllergyLookup,
  FetchProcedureLookup,
  FetchImmunizationLookup,
  FetchConditionLookup,
  ClearFiles,
  FetchStripeReaders,
  SetRestrictedActions,
  FetchLocation,
  ClearLocation,
  PostFile,
  ClearDocsPath,
  FetchFile,
  ClearImageData,
  FetchDownloadable,
  SetAppLanguage,
  UpdateKeycloakUser,
  ClearKeycloakStatus,
  FetchTutorials,
  DownloadTutorial,
  DownloadFile,
} from '../actions/common.actions';
import {
  ListData,
  LookUpData,
  PharmacyListData,
  AllergyData,
  ConditionData,
  ImmunizationData,
  PocedureData,
} from '../interfaces/common.interface';
import { CommonService } from '../services/common.service';
import { UserAuthState } from './user-auth.state';

export class CommonStateModel {
  lookUpData: LookUpData[];
  oscarCallbackUrl: string;
  doctorsList: ListData[];
  OscarAuthorize: boolean;
  lookupPharmacy: PharmacyListData[];
  uploadedFileList: [];
  lookupAllergy: AllergyData[];
  lookupCondition: ConditionData[];
  lookupImmunization: ImmunizationData[];
  lookupProdedure: PocedureData[];
  lookUpStripeReaders: any;
  restrictedActions: any;
  locations: any;
  docsPath: any;
  encodedData: any;
  downloadPath: any;
  appLang: string;
  kecloakUpdate: any;
  tutorials: any;
}
@State<CommonStateModel>({
  name: 'common',
  defaults: {
    lookUpData: undefined,
    doctorsList: undefined,
    oscarCallbackUrl: undefined,
    OscarAuthorize: false,
    lookupPharmacy: undefined,
    uploadedFileList: undefined,
    lookupAllergy: undefined,
    lookupProdedure: undefined,
    lookupCondition: undefined,
    lookupImmunization: undefined,
    lookUpStripeReaders: undefined,
    restrictedActions: undefined,
    locations: undefined,
    docsPath: undefined,
    encodedData: undefined,
    downloadPath: undefined,
    appLang: 'en',
    kecloakUpdate: undefined,
    tutorials: undefined,
  },
})
@Injectable()
export class CommonState {
  constructor(private store: Store, private commonService: CommonService) {}

  @Selector()
  static getLookUp(state: CommonStateModel) {
    return state.lookUpData;
  }
  @Selector()
  static getAllergyLookUp(state: CommonStateModel) {
    return state.lookupAllergy;
  }
  @Selector()
  static getProcedureLookUp(state: CommonStateModel) {
    return state.lookupProdedure;
  }

  @Selector()
  static getImmunizationLookUp(state: CommonStateModel) {
    return state.lookupImmunization;
  }
  @Selector()
  static getConditionLookUp(state: CommonStateModel) {
    return state.lookupCondition;
  }

  @Selector()
  static getPharmacyLookUp(state: CommonStateModel) {
    return state.lookupPharmacy;
  }

  @Selector()
  static getOscarCallbackUrl(state: CommonStateModel) {
    return state.oscarCallbackUrl;
  }
  @Selector()
  static getDoctorsList(state: CommonStateModel) {
    return state.doctorsList;
  }
  @Selector()
  static getOscarAuthorize(state: CommonStateModel) {
    return state.OscarAuthorize;
  }
  @Selector()
  static getFileList(state: CommonStateModel) {
    return state.uploadedFileList;
  }
  @Selector()
  static getStripeReaders(state: CommonStateModel) {
    return state.lookUpStripeReaders;
  }
  @Selector()
  static getRestrictedActions(state: CommonStateModel) {
    return state.restrictedActions;
  }
  @Selector()
  static getLocations(state: CommonStateModel) {
    return state.locations;
  }
  @Selector()
  static getDocsPath(state: CommonStateModel) {
    return state.docsPath;
  }
  @Selector()
  static getEncodedData(state: CommonStateModel) {
    return state.encodedData;
  }
  @Selector()
  static getDownloadableFile(state: CommonStateModel) {
    return state.downloadPath;
  }

  @Selector()
  static getAppLang(state: CommonStateModel) {
    return state.appLang;
  }
  @Selector()
  static getKeycloakUpdate(state: CommonStateModel) {
    return state.kecloakUpdate;
  }
  @Selector()
  static getTutorialsList(state: CommonStateModel) {
    return state.tutorials;
  }

  @Action(clearOscarAuthorizeFlag)
  clearOscarAuthorizeFlag({ patchState }: StateContext<CommonStateModel>) {
    patchState({
      oscarCallbackUrl: undefined,
      OscarAuthorize: false,
    });
  }

  @Action(FetchData)
  fetchData(
    { patchState }: StateContext<CommonStateModel>,
    { payload }: FetchData
  ) {
    let storeValue = this.store.selectSnapshot(CommonState.getLookUp);

    return this.commonService.fetchData(payload).pipe(
      tap((response) => {
        if (response && response.statusCode) {
          if (response.statusCode == 200) {
            if (storeValue) {
              const index = storeValue.findIndex(
                (x) => x.lookupCode === payload
              );
              index >= 0
                ? (storeValue[index] = response.data)
                : storeValue.push(response.data);
            } else {
              storeValue = [];
              storeValue.push(response.data);
            }
          }
        }
        const newLookup = [];
        for (let i = 0, len = storeValue.length; i < len; ++i) {
          newLookup.push(storeValue[i]);
        }
        patchState({
          lookUpData: newLookup,
        });
      })
    );
  }
  @Action(FetchPharmacyData)
  FetchPharmacyData(
    { patchState }: StateContext<CommonStateModel>,
    { payload }: FetchPharmacyData
  ) {
    return this.commonService.fetchData(payload).pipe(
      tap((response) => {
        if (response && response.statusCode) {
          if (
            response.statusCode == 200 &&
            response.data &&
            response.data.lookupValues
          ) {
            patchState({
              lookupPharmacy: response.data.lookupValues,
            });
            return;
          }
        }
        patchState({
          lookupPharmacy: [],
        });
      })
    );
  }
  @Action(FetchConditionLookup)
  FetchConditionLookup(
    { patchState }: StateContext<CommonStateModel>,
    { payload }: FetchConditionLookup
  ) {
    return this.commonService.fetchData(payload).pipe(
      tap((response) => {
        if (response && response.statusCode) {
          if (
            response.statusCode == 200 &&
            response.data &&
            response.data.lookupValues
          ) {
            patchState({
              lookupCondition: response.data.lookupValues,
            });
            return;
          }
        }
        patchState({
          lookupCondition: [],
        });
      })
    );
  }
  @Action(FetchAllergyLookup)
  FetchAllergyLookup(
    { patchState }: StateContext<CommonStateModel>,
    { payload }: FetchAllergyLookup
  ) {
    return this.commonService.fetchData(payload).pipe(
      tap((response) => {
        if (response && response.statusCode) {
          if (
            response.statusCode == 200 &&
            response.data &&
            response.data.lookupValues
          ) {
            patchState({
              lookupAllergy: response.data.lookupValues,
            });
            return;
          }
        }
        patchState({
          lookupAllergy: [],
        });
      })
    );
  }
  @Action(FetchProcedureLookup)
  FetchProcedureLookup(
    { patchState }: StateContext<CommonStateModel>,
    { payload }: FetchProcedureLookup
  ) {
    return this.commonService.fetchData(payload).pipe(
      tap((response) => {
        if (response && response.statusCode) {
          if (
            response.statusCode == 200 &&
            response.data &&
            response.data.lookupValues
          ) {
            patchState({
              lookupProdedure: response.data.lookupValues,
            });
            return;
          }
        }
        patchState({
          lookupProdedure: [],
        });
      })
    );
  }

  @Action(FetchImmunizationLookup)
  FetchImmunizationLookup(
    { patchState }: StateContext<CommonStateModel>,
    { payload }: FetchImmunizationLookup
  ) {
    return this.commonService.fetchData(payload).pipe(
      tap((response) => {
        if (response && response.statusCode) {
          if (
            response.statusCode == 200 &&
            response.data &&
            response.data.lookupValues
          ) {
            patchState({
              lookupImmunization: response.data.lookupValues,
            });
            return;
          }
        }
        patchState({
          lookupImmunization: [],
        });
      })
    );
  }

  @Action(FetchDoctorsList)
  fetchDoctorsList({ patchState }: StateContext<CommonStateModel>) {
    return this.commonService.fetchDoctors().pipe(
      tap((response) => {
        if (response['data']) {
          patchState({
            doctorsList: response['data'],
          });
          return;
        }
      })
    );
  }

  @Action(FetchStripeReaders)
  fetchStripeReaders(
    { patchState }: StateContext<CommonStateModel>,
    { payload }: any
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    // const clinicData = this.store.selectSnapshot(SettingState.getGeneralSettingDetails)
    return this.commonService.fetchStripeReaders(payload, loginInfo.token).pipe(
      tap((response) => {
        if (response['data']) {
          patchState({
            lookUpStripeReaders: response['data'],
          });
        } else {
          patchState({
            lookUpStripeReaders: undefined,
          });
        }
      })
    );
  }
  //Temporary implementation
  @Action(InitOscarIntegration)
  InitOscarIntegration({ patchState }: StateContext<CommonStateModel>) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    if (loginInfo && loginInfo.token) {
      return this.commonService.initOscar(loginInfo.token).pipe(
        tap((response: any) => {
          if (response && response.statusCode) {
            if (response.statusCode == 200) {
              if (response.data) {
                patchState({
                  oscarCallbackUrl: response.data,
                });
              } else {
                patchState({
                  oscarCallbackUrl: undefined,
                });
              }
            }
          }
        })
      );
    }
  }

  @Action(authorizeOscarIntegration)
  authorizeOscarIntegration(
    { patchState }: StateContext<CommonStateModel>,
    { oauthVerifier, oauthToken }: authorizeOscarIntegration
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    if (loginInfo && loginInfo.token) {
      const payload = {
        oauth_verifier: oauthVerifier,
        oauth_token: oauthToken,
      };
      return this.commonService
        .saveOscarVerifierAndToken(loginInfo.token, payload)
        .pipe(
          tap((response: any) => {
            if (response && response.statusCode) {
              if (response.statusCode == 200) {
                if (response.data) {
                  patchState({
                    OscarAuthorize: true,
                  });
                } else {
                  patchState({
                    OscarAuthorize: false,
                  });
                }
              }
            }
          })
        );
    }
  }

  @Action(ClearFiles)
  clearUploads({ patchState }: StateContext<CommonStateModel>) {
    patchState({
      uploadedFileList: [],
    });
  }

  @Action(SetRestrictedActions)
  setRestricted(
    { patchState }: StateContext<CommonStateModel>,
    { payload }: any
  ) {
    patchState({
      restrictedActions: payload ? payload : undefined,
    });
  }

  @Action(FetchLocation)
  fetchLocation(
    { patchState }: StateContext<CommonStateModel>,
    { payload }: any
  ) {
    return this.commonService.fetchLocation(payload).pipe(
      tap((response) => {
        if (response) {
          patchState({
            locations: response.features,
          });
        }
      })
    );
  }

  @Action(ClearLocation)
  clearLocation({ patchState }: StateContext<CommonStateModel>) {
    patchState({
      locations: undefined,
    });
  }
  @Action(PostFile)
  postFile({ patchState }: StateContext<CommonStateModel>, { payload }: any) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );

    return this.commonService.postFile(payload, loginInfo.token).pipe(
      tap((response) => {
        if (response && response.statusCode) {
          if (response.statusCode == 200) {
            patchState({
              docsPath: response.data,
            });
          } else {
            patchState({
              docsPath: undefined,
            });
          }
        }
      })
    );
  }

  @Action(FetchFile)
  getFile({ patchState }: StateContext<CommonStateModel>, { payload }: any) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    const data = this.store.selectSnapshot(CommonState.getEncodedData);
    return this.commonService
      .getFile(
        {
          uniqueId: payload.uniqueId ? payload.uniqueId : '',
          url: payload.url,
        },
        loginInfo.token
      )
      .pipe(
        tap((response) => {
          if (response && response.statusCode == 200) {
            if (payload.type == 'signature') {
              patchState({
                encodedData: {
                  signature: response.data,
                  image: data?.image ? data.image : undefined,
                },
              });
            } else {
              patchState({
                encodedData: {
                  image: response.data,
                  signature: data?.signature ? data.signature : undefined,
                },
              });
            }
          } else {
            patchState({
              encodedData: { signature: undefined, image: undefined },
            });
          }
        })
      );
  }

  @Action(ClearDocsPath)
  clearDocs({ patchState }: StateContext<CommonStateModel>) {
    patchState({
      docsPath: undefined,
    });
  }
  @Action(ClearImageData)
  clearImages({ patchState }: StateContext<CommonStateModel>) {
    patchState({
      encodedData: { signature: undefined, image: undefined },
    });
  }

  @Action(FetchDownloadable)
  getDownloadableFile(
    { patchState }: StateContext<CommonStateModel>,
    { payload }: any
  ) {
    return this.commonService.getDownloadableFile(payload).pipe(
      tap((response) => {
        if (response && response.statusCode) {
          if (response.statusCode == 200) {
            patchState({
              downloadPath: response.data,
            });
          } else {
            patchState({
              downloadPath: undefined,
            });
          }
        }
      })
    );
  }

  @Action(SetAppLanguage)
  setAppLang({ patchState }: StateContext<CommonStateModel>, { payload }: any) {
    if (payload) {
      patchState({
        appLang: payload,
      });
    }
  }

  @Action(ClearKeycloakStatus)
  clearKeycloak({ patchState }: StateContext<CommonStateModel>) {
    patchState({
      kecloakUpdate: null,
    });
  }

  @Action(UpdateKeycloakUser)
  updateKeyCloak(
    { patchState }: StateContext<CommonStateModel>,
    { payload }: any
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );

    if (loginInfo && loginInfo.token) {
      return this.commonService
        .updateKeyCloakData(payload, loginInfo.token)
        .pipe(
          tap((response) => {
            if (response && response.statusCode) {
              // if (response.statusCode == 200) {
                patchState({
                  kecloakUpdate: response,
                });
              // }
            }
          })
        );
    }
  }

  @Action(FetchTutorials)
  fetchTutorialsList(
    { patchState }: StateContext<CommonStateModel>,
    { payload }: any
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    return this.commonService.fetchTutorialsList(loginInfo.token, payload).pipe(
      tap((response) => {
        if (response && response.statusCode) {
          if (response.statusCode == 200) {
            patchState({
              tutorials: response.data,
            });
          }
        }
      })
    );
  }

  @Action(DownloadTutorial)
  downloadTutorial(
    { patchState }: StateContext<CommonStateModel>,
    { payload }: any
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    return this.commonService.getTutorialVideo(payload, loginInfo.token).pipe(
      tap((response) => {
        if (response && response.statusCode) {
          if (response.statusCode == 200) {
            patchState({
              downloadPath: response.data,
            });
          } else {
            patchState({
              downloadPath: undefined,
            });
          }
        }
      })
    );
  }

  @Action(DownloadFile)
  downloadFile(
    { patchState }: StateContext<CommonStateModel>,
    { payload }: any
  ) {
    return this.commonService.downloadFile(payload).pipe(
      tap((response) => {
        console.log('res', response);
        patchState({
          encodedData: {
            signature: undefined,
            image: response.data,
          },
        });
      })
    );
  }
}
