import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Directive({
  selector: '[quipoMandatory]',
})
export class MandatoryDirective implements OnInit, OnChanges {
  @Input() submitted = false;
  @Input() quipoMandatory;
  constructor(private ele: ElementRef, private translate: TranslateService) {}

  ngOnInit(): void {
    this.removeError();
    if (this.submitted) this.checkFieldValidation();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.removeError();
    const change = changes['submitted'];
    if (change && change?.currentValue) {
      this.checkFieldValidation();
    }
  }

  @HostListener('focus', ['$event'])
  handleFocusEvent(event) {
    this.removeError();
  }

  @HostListener('input', ['$event'])
  handleInputEvent(event) {
    this.removeError();
    this.checkFieldValidation();
  }

  @HostListener('blur', ['$event'])
  handleBlurEvent(event) {
    this.removeError();
    this.checkFieldValidation();
  }

  checkFieldValidation() {
    if (
      this.ele.nativeElement.value == null ||
      this.ele.nativeElement.value == '' ||
      this.ele.nativeElement.value.trim().length == 0
    ) {
      this.showError();
    } else {
      this.removeError();
    }
  }

  private showError() {
    const errorSpanId = this.ele.nativeElement?.name;
    if (errorSpanId) {
      this.removeError();
      const errorMsg =
        this.translate.instant(errorSpanId) +
        ' ' +
        this.translate.instant('is-required');
      const errSpan =
        '<span class="form-validation-errors" id="' +
        errorSpanId +
        '"> <strong>' +
        errorMsg +
        '<strong> </span>';
      this.ele.nativeElement.parentElement.insertAdjacentHTML(
        'afterend',
        errSpan
      );
    }
  }

  private removeError() {
    const errorSpanId = this.ele.nativeElement?.name;
    if (errorSpanId) {
      const errorElement = document.getElementById(errorSpanId);
      if (errorElement) errorElement.remove();
    }
  }
}
