<div class="form-div">
  <img
    quipoTooltip="Toggle Calender" [tooltipPosition]="'bottom'"
    [ngClass]="{ 'icon-background': icon?.background }"
    [src]="icon?.path"
    *ngIf="icon?.isPresent"
    (click)="dp.toggleCalendar()"
    class="calender-button"
    [class.disablecalendericon]="isDisable"
  />

  <img
    quipoTooltip="Clear" [tooltipPosition]="'bottom'"
    [src]="crossicon.path"
    class="clear-date"
    *ngIf="
      (((dateinputdata != '' || dp.hostText != '') && crossflag) ||
        isNumber(mydate.value?.singleDate?.date?.day) ||
        isNumber(mydate.value?.singleDate?.date?.month) ||
        isNumber(mydate.value?.singleDate?.date?.year)) &&
      !isDisable
    "
    (click)="clearInput(dp)"
  />
  <input
    [class.disableCalendar]="isDisable"
    class="form-input"
    type="text"
    [required]="isRequired"
    [placeholder]="config?.placeholder ? config.placeholder : 'YYYY-MM-DD'"
    angular-mydatepicker
    name="mydate"
    (click)="dp.closeCalendar()"
    (dateChanged)="dateSelected($event)"
    (change)="dateSelected($event)"
    [(ngModel)]="model"
    [options]="myDpOptions"
    #mydate="ngModel"
    #dp="angular-mydatepicker"
    autocomplete="off"
    (calendarViewChanged)="onCalendarViewChanged($event)"
    (viewActivated)="onViewActivated($event)"
    (input)="detectValueChange($event)"
    maxlength="10"
  />
  <!-- (input)="dateInput($event)" -->
  <!--(viewActivated)="onChangeSelection($event)"  (calendarViewChanged)="onCalendarViewChanged($event)" -->
  <label class="form-label"
    >{{ label | translate }}
    <span *ngIf="isRequired" class="mandatory-field">*</span>
  </label>
</div>
<div [hidden]="hideError" class="error-div">
  <strong
    class="form-validation-errors"
    *ngIf="
      (mydate.touched && mydate.dirty && isInvalid) ||
        (isEdit && isInvalid) ||
        (isMandatory && submitted && !value);
      else validationError
    "
    >{{ 'date-invalid' | translate }}</strong
  >
</div>

<ng-template #validationError>
  <div
    *ngIf="
      isMandatory &&
      (mydate.dirty || mydate.touched || submitted) &&
      (dp.selectedValue == null ||
        (dp.selectedValue.date.day == 0 &&
          dp.selectedValue.date.month == 0 &&
          dp.selectedValue.date.year == 0))
    "
    class="form-validation-errors"
  >
    <strong>{{ 'field-required' | translate }}</strong>
  </div>
</ng-template>
