import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import {
  BaseComponent,
  ClearAppointmentsList,
  ClearCareteams,
  ClearHealthSummaryAllergies,
  ClearHealthSummaryConditions,
  ClearHealthSummaryHealthDocuments,
  ClearHealthSummaryImmunizations,
  ClearHealthSummaryMedications,
  ClearHealthSummaryProcedures,
  ClearInvoiceList,
  ClearRecord,
  ClearResourcesList,
  ClearShareHealthSummaryDetails,
  ClearToDoResponse,
  ClearTwilioToken,
  InitTwilio,
  LoginInfo,
  UserAuthState,
  FamilyMemberState,
  ClearAnswerResponse,
  ClearSkipNewPatientQuestionnaire,
  SetRestrictedActions,
  VirtualCareState,
  SweetAlertService,
  SetVideoCallStatus,
  ClearQuestionnaire,
} from '@quipo/quipo-lib';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Permissions } from '../permissions';

@Injectable()
export class AuthGuard extends BaseComponent implements CanActivate {
  @Select(UserAuthState.getUserAuthDetails) userDetails$: Observable<LoginInfo>;
  @Select(VirtualCareState.getVideoCallStatus)
  videoCallStatus$: Observable<any>;
  userDetails: any;
  userToken: any;
  isQuestionnaireCompleted: boolean;
  isQuestionnaireSkipped: boolean;
  patientId: string;
  isOutStandingInvoice: boolean;
  counter: number;
  permission = Permissions;
  callStatus: boolean;

  constructor(
    private router: Router,
    public store: Store,
    public alert: SweetAlertService
  ) {
    super(store);
    this.callStatus = false;
    this.userDetails$.pipe(takeUntil(this.destroy$)).subscribe((userInfo) => {
      this.userDetails = userInfo;
      this.userToken = userInfo.token;
      this.isQuestionnaireCompleted = false;
      this.isQuestionnaireSkipped = false;
      this.isOutStandingInvoice = false;

      const selectedPatient = this.store.selectSnapshot(
        FamilyMemberState.getSelectedPatient
      );
      if (selectedPatient && selectedPatient.id) {
        this.isQuestionnaireCompleted = selectedPatient.newPaientQuestonnaire
          ? true
          : false;
        this.isQuestionnaireSkipped = selectedPatient.isQuestionnaireSkipped;

        this.patientId = selectedPatient.id;
        this.isOutStandingInvoice = selectedPatient.isOutStandingInvoice;
      } else {
        this.isQuestionnaireCompleted = userInfo.isQuestionnaireCompleted;
        this.isQuestionnaireSkipped = userInfo.isQuestionnaireSkipped;
        this.patientId = userInfo.patientId;
        this.isOutStandingInvoice = userInfo.isOutStandingInvoice;
      }
    });
    this.videoCallStatus$.pipe(takeUntil(this.destroy$)).subscribe((status) => {
      this.callStatus = status;
    });
  }
  getUserTocken() {
    let userToken: any = undefined;
    userToken = sessionStorage.getItem('loggedUserToken');
    if (!userToken) userToken = localStorage.getItem('loggedUserToken');
    return userToken ? true : false;
  }
  canActivate(route: ActivatedRouteSnapshot) {
    const authDetails = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    if (route.routeConfig.path == 'help') return true;
    if (authDetails && authDetails.token && this.getUserTocken()) {
      const selectedPatient = this.store.selectSnapshot(
        FamilyMemberState.getSelectedPatient
      );
      if (route.routeConfig.path == 'signup/:id') return true;
      if (selectedPatient && selectedPatient.id) {
        this.isQuestionnaireCompleted = selectedPatient.newPaientQuestonnaire
          ? true
          : false;
        this.patientId = selectedPatient.id;
        this.isQuestionnaireSkipped = selectedPatient.isQuestionnaireSkipped;
        this.isOutStandingInvoice = selectedPatient.isOutStandingInvoice;
      }
      //  this.store.dispatch(new FetchToken());
      this.clearStoreData(route?.url[0].path);
      if (
        !authDetails.userRoles ||
        (authDetails.userRoles && authDetails.userRoles.length <= 0)
      ) {
        this.router.navigate(['/']);
        return false;
      }
      if (
        this.isOutStandingInvoice &&
        authDetails.userRoles.includes('patient') &&
        route.routeConfig.path == 'dashboard'
      ) {
        const counter = JSON.parse(localStorage.getItem('counter'));
        if (counter == 0) {
          this.router.navigate(['/patient/billing']);
          localStorage.setItem('counter', '1');
          return false;
        }
        if (counter == -1) {
          this.router.navigate(['/patient/to-do']);
          localStorage.setItem('counter', '1');
          return false;
        }
      }
      if (
        (!this.isQuestionnaireCompleted ||
          selectedPatient?.newPaientQuestonnaire == '') &&
        !this.isQuestionnaireSkipped &&
        route.routeConfig.path != '/' &&
        route.routeConfig.path != 'questionnaire' &&
        route.routeConfig.path != 'dashboard'
      ) {
        if (authDetails.userRoles.includes('patient')) {
          this.store.dispatch(new ClearQuestionnaire());
          this.router.navigate(['/patient/questionnaire'], {
            queryParams: {
              isnewpatient: 1,
              id: this.patientId,
            },
          });
          return false;
        }
      }

      if (window.location.pathname.includes('/patient/')) {
        if (!authDetails.userRoles.includes('patient')) {
          if (
            window.location.pathname.includes('/patient/appointment-video-call')
          ) {
            try {
              const id = window.location.href.split('?id=')[1];
              if (!id) {
                this.router.navigate(['/']);
                return false;
              } else {
                this.router.navigate(['/clinic/appointment-video-call'], {
                  queryParams: {
                    videoCallId: id,
                  },
                });
                return false;
              }
            } catch {
              this.router.navigate(['/']);
              return false;
            }
          } else if (
            authDetails.userRoles.includes('checkIn') &&
            window.location.pathname.includes('/patient/questionnaire')
          ) {
            this.store.dispatch(new ClearQuestionnaire());
            try {
              const queryString = window.location.href.split(
                '?isCovidScreening='
              )[1];
              if (!queryString) {
                this.router.navigate(['/']);
                return false;
              } else {
                // const isCheckInIndex = queryString.indexOf('isCheckIn=1');
                // if (isCheckInIndex >= 0) {
                //   this.router.navigate(['/clinic/check-in'], {
                //     queryParams: {
                //       allow: 'yes',
                //     },
                //   });
                // } else {
                //   this.router.navigate(['/clinic/check-in'], {
                //     queryParams: {
                //       allow: 'no',
                //     },
                //   });
                // }
                return true;
              }
            } catch {
              this.router.navigate(['/']);
              return false;
            }
          } else if (
            window.location.pathname.includes('/patient/questionnaire') &&
            authDetails.userRoles.includes('checkIn')
          ) {
            this.store.dispatch(new ClearQuestionnaire());
            return true;
          } 
          else {            
            this.router.navigate(['/']);
            return false;
          }
        }
      } else if (window.location.pathname.includes('/clinic/')) {
        if (this.callStatus) {
          this.alert.simpleAlert(
            'Consultation',
            'Please drop the call before leaving the page.'
          );
          if (
            route.routeConfig.path == 'physician' ||
            route.routeConfig.path == 'virtual-care/start-call'
          ) {
            this.store.dispatch(new SetVideoCallStatus(false));
            return true;
          } else return false;
        } else if (
          authDetails.userRoles.length == 1 &&
          authDetails.userRoles.includes('patient')
        ) {
          this.router.navigate(['/']);
          return false;
        }
      }

      if (authDetails.userRoles && authDetails.userRoles[0]) {
        if (
          this.permission.hasOwnProperty.call(
            this.permission,
            authDetails.userRoles[0]
          )
        ) {
          return this.checkForPermission(
            route.pathFromRoot,
            this.permission[authDetails.userRoles[0]].routes
          );
        }
      }

      return true;
    } else {
      const linkId = sessionStorage.getItem('linkId');
      if (linkId && window.location.pathname.includes('/patient/'))
        return true;

      if (
        window.location.pathname.includes('/patient/appointment-video-call')
      ) {
        try {
          const id = window.location.href.split('?id=')[1];
          if (id) {
            this.router.navigate(['/'], {
              queryParams: {
                videoCallId: id,
              },
            });
          } else {
            this.router.navigate(['/']);
            return false;
          }
        } catch {
          this.router.navigate(['/']);
          return false;
        }
      } else {
        this.router.navigate(['/']);
        return false;
      }
    }
  }

  getRouteArray(route) {
    const arrData = [];
    for (let i = 0; i < route.length; i++) {
      if (route[i].routeConfig && route[i].routeConfig.path) {
        arrData.push(route[i].routeConfig.path);
      }
    }
    return arrData;
  }

  checkForPermission(activatedRoute, allowedRoute: any) {
    const routeArray = this.getRouteArray(activatedRoute);
    try {
      for (let i = 0; i < routeArray.length; i++) {
        for (let j = 0; j < allowedRoute.length; j++) {
          if (routeArray[i] == allowedRoute[j].routeName) {
            if (!allowedRoute[j].fullPermission) {
              if (i == routeArray.length - 1) {
                if (allowedRoute[j].restricted)
                  this.store.dispatch(
                    new SetRestrictedActions(allowedRoute[j].restricted)
                  );
                else if (allowedRoute[j].tabs)
                  this.store.dispatch(
                    new SetRestrictedActions(allowedRoute[j].tabs)
                  );
              }
              if (allowedRoute[j].routes) {
                allowedRoute = allowedRoute[j].routes;
              }
              break;
            } else {
              this.store.dispatch(new SetRestrictedActions(undefined));
              return true;
            }
          } else {
            if (j == allowedRoute.length - 1) {
              return false;
            }
          }
        }
      }
      return true;
    } catch {
      return false;
    }
  }

  clearStoreData(url) {
    if (this.userToken) {
      switch (url) {
        // case 'dashboard':
        //   this.store.dispatch(new ClearFamilyMembers());
        //   break;
        case 'appointments':
          this.store.dispatch(new ClearAppointmentsList());
          break;
        case 'care-teams':
          this.store.dispatch(new ClearCareteams());
          break;
        case 'billing':
          this.store.dispatch(new ClearInvoiceList());
          break;
        case 'to-do':
          this.store.dispatch(new ClearToDoResponse());
          break;
        case 'resources':
          this.store.dispatch(new ClearResourcesList());
          break;
        case 'track-my-health':
          this.store.dispatch(new ClearRecord());
          break;
        case 'conditions':
          this.store.dispatch(new ClearHealthSummaryConditions());
          break;
        case 'procedures':
          this.store.dispatch(new ClearHealthSummaryProcedures());
          break;
        case 'allergies':
          this.store.dispatch(new ClearHealthSummaryAllergies());
          break;
        case 'medications':
          this.store.dispatch(new ClearHealthSummaryMedications());
          break;
        case 'immunizations':
          this.store.dispatch(new ClearHealthSummaryImmunizations());
          break;
        case 'health-documents':
          this.store.dispatch(new ClearHealthSummaryHealthDocuments());
          break;
        case 'share':
          this.store.dispatch(new ClearShareHealthSummaryDetails());
          break;
        case 'appointment-video-call':
          this.store.dispatch(new ClearTwilioToken());
          this.store.dispatch(new InitTwilio());
          break;

        case 'questionnaire':
          this.store.dispatch(new ClearAnswerResponse());
          this.store.dispatch(new ClearQuestionnaire());
          this.store.dispatch(new ClearSkipNewPatientQuestionnaire());
          break;

        default:
          break;
      }
    } else {
      this.router.navigate(['/']);
    }
  }
}
