import { OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import {
  ClearAppointmentState,
  ClearFamilyMembers,
  ClearHealthSummary,
  ClearImageData,
  ClearRecord,
  ClearSignupResponse,
  FetchFile,
  FetchToken,
  ClearKeycloackUserResponse,
} from '../actions';

import { UserAuthState } from '../state/user-auth.state';

import * as JSZip from 'jszip';
import * as JSZipUtils from 'jszip-utils';
import { saveAs } from 'file-saver';
import { takeUntil } from 'rxjs/operators';
import { CommonState } from '../state/common.state';
import { Base64Service } from './base64.service';
import { DomSanitizer } from '@angular/platform-browser';
@Injectable()
export abstract class BaseComponent implements OnInit, OnDestroy {
  showModalPopup: boolean;
  title: string;
  htmlContent: any;
  private base64Service: Base64Service;
  public domsanitizer: DomSanitizer;
  private _destroy$: Subject<any>;
  submitted = false;
  isValid = true;
  fileData = [];
  showAlert = false;
  eventListenerAdded = false;
  coordinates: string;
  @Select(CommonState.getEncodedData) encodedFile$: Observable<any>;
  getUserTocken() {
    let userToken: any = undefined;
    userToken = sessionStorage.getItem('loggedUserToken');
    if (!userToken) userToken = localStorage.getItem('loggedUserToken');
    return userToken;
  }
  constructor(public store: Store) {
    this.showModalPopup = false;
    this.base64Service = new Base64Service(this.domsanitizer);
    const authDetails = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    if (authDetails && authDetails.token && this.getUserTocken()) {
      const expiry = JSON.parse(atob(authDetails.token.split('.')[1])).exp;
      const needRefresh =
        Math.floor(new Date().getTime() / 1000) >= expiry ||
        expiry - Math.floor(new Date().getTime() / 1000) < 300;

      if (needRefresh) {
        this.store.dispatch(new FetchToken());
      }
    }

    this.encodedFile$.pipe(takeUntil(this.destroy$)).subscribe((path) => {
      if (path && path.image) this.fileData.push(path.image);
    });
  }
  get destroy$() {
    if (!this._destroy$) {
      this._destroy$ = new Subject();
    }
    return this._destroy$;
  }
  ngOnDestroy() {
    if (this._destroy$) {
      this._destroy$.next(true);
      this._destroy$.complete();
    }
  }

  validateControls() {
    this.submitted = true;
    const elements = document.querySelectorAll('.form-validation-errors');
    if (elements && elements.length > 0) this.isValid = false;
    else this.isValid = true;
  }

  clearState() {
    this.store.dispatch(new ClearAppointmentState());
    this.store.dispatch(new ClearFamilyMembers());
    this.store.dispatch(new ClearHealthSummary());
    //this.store.dispatch(new ClearLoginDetals());
    //this.store.dispatch(new ClearProfileData());
    this.store.dispatch(new ClearRecord());
    this.store.dispatch(new ClearSignupResponse());
    this.store.dispatch(new ClearKeycloackUserResponse());
  }

  async downloadAsZip(fileArr, title) {
    this.fileData = [];
    this.store.dispatch(new ClearImageData());
    if (fileArr) {
      if (typeof fileArr[0] == 'string') {
        await fileArr.forEach((filePath) => {
          this.store.dispatch(new FetchFile({ url: filePath }));
        });
      } else {
        await fileArr.forEach((filePath) => {
          this.store.dispatch(
            new FetchFile({ uniqueId: filePath.id, url: filePath.fileUrl })
          );
        });
      }
      const populateData = window.setInterval(() => {
        if (this.fileData && this.fileData.length == fileArr.length) {
          this.getZipFile(title);
          clearInterval(populateData);
        }
      }, 1000);
    }
  }

  getZipFile(title) {
    const zip = new JSZip();
    let count = 0;
    const zipFilename = title + '.zip';
    const that = this;
    that.fileData.forEach((file) => {
      const filename = file.fileName.split('-');

      // loading a file and add it in a zip file
      JSZipUtils.getBinaryContent(file.base64Content, function (err, data) {
        if (err) {
          throw err; // or handle the error
        }
        zip.file(filename[filename.length - 1], data, { binary: true });
        count++;
        if (count == that.fileData.length) {
          zip.generateAsync({ type: 'blob' }).then(function (content) {
            saveAs(content, zipFilename);
          });
        }
      });
    });
  }

  getVisibility(title, restriction) {
    if (restriction && restriction.length && restriction.length > 0) {
      if (restriction.includes(title)) return true;
    }
    return false;
  }

  getUUID() {
    return Date.now().toString(36) + Math.random().toString(36).substring(2);
  }

  capitalizeString(str) {
    if (str) {
      const formattedStr = str.charAt(0).toUpperCase() + str.slice(1);
      return formattedStr;
    }
  }

  async downloadDocuments(fileArr, title) {
    this.fileData = [];
    this.store.dispatch(new ClearImageData());
    if (fileArr) {
      if (typeof fileArr[0] == 'string') {
        await fileArr.forEach((filePath) => {
          this.store.dispatch(new FetchFile({ url: filePath }));
        });
      } else {
        await fileArr.forEach((filePath) => {
          this.store.dispatch(
            new FetchFile({ uniqueId: filePath.id, url: filePath.fileUrl })
          );
        });
      }
      const populateData = window.setInterval(() => {
        if (this.fileData && this.fileData.length == fileArr.length) {
          if (fileArr.length == 1) {
            // Only one file, then show the file in a new window
            this.getdocumentsFile(title);
            clearInterval(populateData);
          } else {
            this.htmlContent = this.displayListofDocumentsForPopup(title);
            this.showModalPopup = true;
            setTimeout(() => {
              const elem = document.getElementById('popupHtmlContent');
              if (elem) {
                elem.innerHTML = this.htmlContent;
              }
            }, 10);

            this.title = title;
            clearInterval(populateData);
          }
        }
      }, 10);
    }
  }
  getdocumentsFile(title) {
    const that = this;
    const fileType = this.getFileExtension(that.fileData[0].fileName);
    const newstr = this.replaceString(fileType, that.fileData[0].base64Content);
    let filename = that.fileData[0].fileName.split('_');
    filename = filename[1] ? filename[1] : that.fileData[0].fileName;
    this.base64Service.openFileInNewWindow(newstr, fileType, filename);
  }
  displayListofDocumentsForPopup(title: string): any {
    let htmlContent = '';
    this.fileData.forEach((file) => {
      let filename = file.fileName.split('_');
      filename = filename[1] ? filename[1] : file.fileName;
      const extension = this.getFileExtension(filename);
      const encodedFileName = encodeURIComponent(file.fileName);
      const encodedBase64Content = encodeURIComponent(file.base64Content);
      const fileType = this.getFileExtension(file.fileName);
      filename = extension ? filename : filename + '.' + fileType;
      const fileIcon = this.getFileIcon(fileType);
      const newstr = this.replaceString(fileType, file.base64Content);
      htmlContent += `<div style="cursor: pointer;margin: 2em;color: #3d77c5;font-family: SF-Compact-Display-Regular;font-weight: bold;display: flex;">
          <img src="${fileIcon}" alt="View" style="width: 40px; height: 40px; vertical-align: middle; margin-right: 10px;">
          <span onclick="window.postMessage({ fileName: '${file.fileName}', base64Content: '${encodedBase64Content}' }, '*');return false;" style="margin-top: 10px;">${filename}</span>
        </div>
      `;

      const eventListenerCallback = (event) => {
        if (event.data.fileName && event.data.base64Content) {
          const fileName = decodeURIComponent(event.data.fileName);
          const base64Content = decodeURIComponent(event.data.base64Content);
          this.openNewWindow(fileName, base64Content);
          return false;
        }
      };

      // Check if the event listener is already added
      if (!this.eventListenerAdded) {
        window.addEventListener('message', eventListenerCallback);
        this.eventListenerAdded = true;
      }
    });

    return htmlContent;
  }
  ngOnInit() {}
  openNewWindow(fileName: string, base64Content: string): void {
    const fileType = this.getFileExtension(fileName);
    const newstr = this.replaceString(fileType, base64Content);
    this.base64Service.openFileInNewWindow(newstr, fileType, fileName);
  }
  getFileExtension(fileName: string): string {
    const parts = fileName.split('.');
    if (parts.length > 1) {
      return parts[parts.length - 1];
    }
    return '';
  }
  replaceString(fileType: string, data: string): string {
    switch (fileType) {
      case 'pdf':
        return data.replace('data:image/pdf;base64,', '');
      case 'docx':
        return data.replace(
          'data:image/docx;base64,dataapplication/vndopenxmlformats+officedocumentwordprocessingmldocumentbase64',
          ''
        );
      case 'doc':
        return data.replace('data:image/doc;base64,', '');
      case 'txt':
        return data.replace('data:image/txt;base64,datatext/plainbase64', '');
      case 'png':
        return data.replace('data:image/png;base64,', '');
      case 'jpeg':
        return data.replace('data:image/jpeg;base64,', '');
      case 'jpg':
        return data.replace('data:image/jpg;base64,', '');
      case 'PNG':
        return data.replace('data:image/PNG;base64,', '');
      default:
        return data;
    }
  }
  getFileIcon(fileType: string): string {
    switch (fileType) {
      case 'pdf':
        return '../../../../../../../assets/images/pdf.svg';
      case 'docx':
        return '../../../../../../../assets/images/docx.svg';
      case 'doc':
        return '../../../../../../../assets/images/doc.svg';
      case 'txt':
        return '../../../../../../../assets/images/txt.svg';
      case 'png':
        return '../../../../../../../assets/images/image-file.svg';
      case 'png':
        return '../../../../../../../assets/images/image-file.svg';
      case 'jpeg':
        return '../../../../../../../assets/images/image-file.svg';
      case 'PNG':
        return '../../../../../../../assets/images/image-file.svg';
      case 'jpg':
        return '../../../../../../../assets/images/image-file.svg';
      default:
        return '';
    }
  }
}
